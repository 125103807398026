import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { fetchTeacherEngagement } from '../../../store/dashboard/Actions';
import { CommonState } from '../../../components/type';
import CommonLoader from '../../../components/CommonLoader';
import history from '../../../History';
import Cookies from 'universal-cookie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { stopYearData, stopSubComponentYear } from '../../../store/profile/Actions'

export type PostsTeacherProps = {
	stopYearDate?:any
	subComponentStatus?:any
	getChangeYearData?:any;
	fetchTeacherEngagement:(getValue:any) => any;
	stopYearData:()=>any;
	stopSubComponentYear:()=>any;
    loading?:any;
	loadingDashboard?:any;
	getProfile?:any;
	getTeacherEngagement?:any;
	getSchoolId?:any;
	TotalTeacherEngagement?:any;
	PerPageTeacherEngagement?:any;
	PageTeacherEngagement?:any;
	teacherEngagementLoader?:boolean;
}
export class TeacherEngagementView extends Component<PostsTeacherProps, CommonState> {
    cookies:any = new Cookies();
	constructor(props: any) {
		super(props);
		this.state = {
			page:1,
			page_no: 1,
			search: '',
            hasMore: true,
			academicYear:null,
			class_id:null,
			messageData:'',
			TeacherEngagement:[],
			show:true
		}
	}
    componentDidMount(): void {
		window.scrollTo(0, 0);
		this.getCurrectYear()
	}
	componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate, subComponentStatus} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}else if(subComponentStatus === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}else {

		}
	}
	componentDidUpdate() {
		const {stopYearDate, subComponentStatus} = this.props;
		if(subComponentStatus === true){
			this.props.stopSubComponentYear()
		}else if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		this.setState({show:false})
		if(getYear && getYear !== undefined && getYear !== null){
			this.viewUserTypes(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.viewUserTypes(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
    viewUserTypes(getYear?:any){
		const { getProfile } = this.props;
		if(getProfile && getProfile !== undefined && getProfile !== null){
		if(getYear){
			this.getTeacherEngagement(getYear)
		}
	}
	}
    getTeacherEngagement(getYear?:any){
		const { getSchoolId, getProfile } = this.props;
		const {page} = this.state;
		if(getProfile && getProfile !== undefined && getProfile !== null){
			if(getProfile.usertype === 1){
				const getTeacher:any = {
					academic_year: getYear,
					page_no: page,
					school_id:getSchoolId
				  }
				  this.props.fetchTeacherEngagement(getTeacher).then((res:any)=>{
					const { getTeacherEngagement } = this.props;
					if(getTeacherEngagement && getTeacherEngagement !== undefined && getTeacherEngagement !== null){
						if(getTeacherEngagement.length > 0){
							this.setState({TeacherEngagement:getTeacherEngagement})
						}else {
							this.setState({TeacherEngagement:[]})
						}
					}
				});
			} else if(getProfile.usertype === 2 || getProfile.usertype === 3) {
				const getTeacher:any = {
					academic_year: getYear,
					page_no: page
				  }
				  this.props.fetchTeacherEngagement(getTeacher).then((res:any)=>{
					const { getTeacherEngagement } = this.props;
					if(getTeacherEngagement && getTeacherEngagement !== undefined && getTeacherEngagement !== null){
						if(getTeacherEngagement.length > 0){
							this.setState({TeacherEngagement:getTeacherEngagement})
						}else {
							this.setState({TeacherEngagement:[]})
						}
					}
				});;
			}
		}
	}
    public fetchTeacherEngagement = () => {
		const {academicYear} = this.state
        if (this.state.hasMore === true) {
         if (Math.ceil(this.props.TotalTeacherEngagement / this.props.PerPageTeacherEngagement) > this.props.PageTeacherEngagement) {   
            const postValue:any = {
              page_no: this.state.page + 1,
              search: this.state.search,
              sort_by: this.state.SortOrderData,
              order_by: this.state.OrderNameData,
              academic_year: academicYear
            }              
            this.props.fetchTeacherEngagement(postValue);
            this.setState({
                page: this.state.page + 1
            }) 
          }
    
          if (Math.ceil(this.props.TotalTeacherEngagement / this.props.PerPageTeacherEngagement) === this.props.PageTeacherEngagement) {
            this.setState({
              hasMore: false,
            })
          }
        }
      }
      postTeacherDetails= (getValue:any) =>{
		const { getSchoolId } = this.props;		
        this.cookies.set('ACADEMICSCHOOLID', getSchoolId)
		history.push({
			pathname: `/teachers/${getValue.teacher_id}`,
		  });
		  localStorage.setItem('TeacherDetails', getValue.teacher_name)
	}
	addDefaultSrc= (ev:any) =>{
        ev.target.src = '../assets/img/user/teacher-profile.jpg'
      }
      refreshTeacherEngagement = () => {
        const {academicYear} = this.state;
        this.viewUserTypes(academicYear);
    }
    render() {
        const { teacherEngagementLoader } = this.props;
		const { TeacherEngagement } = this.state;
		const teacherEngagementLoaderVisibility = { display: teacherEngagementLoader ? "block" : "none" };
        return (
            <div>
                	<div className="card card-topline-red">
						<div className="overlay" style={teacherEngagementLoaderVisibility}><div className='custom-spinner-individual'></div></div>
							<div className="card-head padding15 pb-2">
								<header>Teacher Engagement</header>
								<div className="tools">
									<div className="d-flex">
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh" onClick={this.refreshTeacherEngagement}></span>
											<span className="t-collapse btn-color fa fa-chevron-down"></span>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body ">
								<InfiniteScroll
                                dataLength={TeacherEngagement.length}
                                next={this.fetchTeacherEngagement}
                                hasMore={this.state.hasMore}
                                loader={<h4 style={teacherEngagementLoaderVisibility}>Loading...</h4>}
                                >
									<ul className="docListWindow small-slimscroll-style overflowBox width100">
										<li className="title-sticky" style={{zIndex:2}}>
											<div className="row">
												<div className="col-md-4"><strong>Teacher name</strong></div>
												<div className="col-md-4 text-center"><strong>Engagements per class</strong></div>
												<div className="col-md-4 text-center"><strong>Total Engagements</strong></div>
											</div>
										</li>
										{TeacherEngagement.length ?
										<>
										{TeacherEngagement.map((items:any, index:any)=>(
											<li key={index}>
											<div className="row">
												<div className="col-md-4 col-sm-4">
												{ items.teacher_profile?
                                                         <div>
                                                         <div className="profileImage" >
                                                        <img onError={this.addDefaultSrc} src={`${process.env.REACT_APP_API_URL}${items.teacher_profile}`} 
                                                        alt="Student Profile" width="40"
                                                        height="40"/>
                                                                    </div>
                                                     </div>
                                                        :
                                                       <div className="prog-avatar">
														<button
															className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ml-3 btn-pink"
															onClick={()=> this.postTeacherDetails(items)}>
															<span>{items.teacher_name.charAt(0)}</span>											</button>
													</div>
                                                        }
													
													<div className="details">
														<div className="title mt-2">
															<Link to={"#"} onClick={()=> this.postTeacherDetails(items)}>{items.teacher_name}</Link>

														</div>
													</div>
												</div>
												<div className="col-md-4 col-sm-4 rating-style ">
													<p style={{textAlign:'center'}}>{items.engaegement_per_class}</p>
												</div>
												<div className="col-md-4 col-sm-4 rating-style ">
													<p style={{textAlign:'center'}}>{items.total_engagement}</p>
												</div>
											</div>
										</li>
										))
										}	
										</>
										:<CommonLoader/>}							
									</ul>
                                    </InfiniteScroll>
							</div>
						</div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) =>{
	return{
		loading:state.profile.loading,
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
		subComponentStatus:state.profile.subComponentStart,
		loadingDashboard:state.dashboard.loading,
        getProfile:state.profile.profileData,
		getTeacherEngagement:state.dashboard.records_Teacher_Engagement,
		TotalTeacherEngagement:state.dashboard.total_Teacher_Engagement,
		PerPageTeacherEngagement:state.dashboard.per_page_Teacher_Engagement,
		PageTeacherEngagement:state.dashboard.page_Teacher_Engagement,
		teacherEngagementLoader:state.dashboard.teacherEngagementLoader
	}
}

export default connect(mapStateToProps, {fetchTeacherEngagement, stopYearData, stopSubComponentYear})(TeacherEngagementView)
