import { FormGroup, Button } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { UserType } from '../../store/usermanage/Type';
import { UserManageValidation } from './profileValidate';
import {ReqChangePassword} from '../../store/usermanage/Actions';

export type OwnUserInfoFormProps = {
    getChangeYearData?:any;
	stopYearDate?:any;
    postChangePassword:(postValue:any) => void;
    loading: boolean;    
};
// export interface PostsListProps {
//     loading: boolean;
// }
interface StateTypes {
    show?: boolean,
    userDetail?: any,
    getInitialValues?: any,
    showPasswordValue?: any
}
class UserProfile extends React.Component<OwnUserInfoFormProps, StateTypes>{
    formikUser: any
    constructor(props: any) {
        super(props);
        this.state = {
            show: false,
            userDetail: props.location.state ? props.location.state : null,
            getInitialValues: null,
            showPasswordValue: false
        }
    }
    componentDidMount(): void {
        const { userDetail } = this.state;
        if (userDetail) {
            const initialUserValues: UserType = {               
                email_id: userDetail.email,
                phone_number: userDetail.phone_number,
                role: userDetail.role,
                school_id: userDetail.school_id,
                old_password:'',
                new_password:'',
                confirmpassword:'',
                timezone: 'Asia/Kolkata'
            }
            this.setState({ getInitialValues: initialUserValues })
        }
    }
    onChangePasswordShow = () => {
        if (this.state.showPasswordValue) {
            this.setState({ showPasswordValue: !this.state.showPasswordValue })
        } else {
            this.setState({ showPasswordValue: !this.state.showPasswordValue })
        }
    }
    render() {
        const { userDetail, getInitialValues } = this.state;
        const loadingTextCSS = { display: userDetail ? "none" : "block" };
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['User']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['User']}
                                baseURL={['user']}
                                mainPageTitle={['Change Password']} />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box">
                                        <div className="card-head">
                                            <header>Change Password</header>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        getInitialValues ?
                                                            <Formik
                                                                ref={node => this.formikUser = node}
                                                                initialValues={getInitialValues}
                                                                onSubmit={(values: UserType, actions) => {
                                                                    let postUserInfo:any = {
                                                                        email_id:values.email_id,
                                                                        oldpassword:values.old_password,
                                                                        newpassword:values.new_password,
                                                                        confirmpassword:values.confirmpassword
                                                                    }
                                                                    this.props.postChangePassword(postUserInfo)
                                                                }}
                                                                validationSchema={UserManageValidation}
                                                                render={({
                                                                    values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit
                                                                }: FormikProps<UserType>) => {
                                                                    const isUserEmpty = (!values.old_password
                                                                        || !values.new_password || !values.confirmpassword);
                                                                    return (
                                                                        <form onSubmit={handleSubmit}>
                                                                            <div>           
                                                                                <div className="row">
                                                                                    <div className="col-md-6 p-t-20">
                                                                                        <div>
                                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                                <FormGroup>
                                                                                                    <Field
                                                                                                        label={<><span>Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                                                        type="text"
                                                                                                        name="email_id"
                                                                                                        component={TextField}
                                                                                                        className="textfield__input"
                                                                                                        disabled={true}
                                                                                                        value={values?.email_id}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6 p-t-20">
                                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                                    <FormGroup>
                                                                                                        <Field
                                                                                                            label={<><span>Old Password</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            type="password"
                                                                                                            name="old_password"
                                                                                                            component={TextField}
                                                                                                            className="textfield__input"
                                                                                                            disabled={false}
                                                                                                        />
                                                                                                    </FormGroup>
                                                                                                </div>
                                                                                            </div>
                                                                                </div>
                                                                               
                                                                                        <div className="row">
                                                                                        <div className="col-md-6 p-t-20">
                                                                                                <div>
                                                                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                                        <FormGroup>
                                                                                                            <Field
                                                                                                                label={<><span>New Password</span><span className='formmandatorycolor'> *</span></>}
                                                                                                                type="password"
                                                                                                                name="new_password"
                                                                                                                component={TextField}
                                                                                                                className="textfield__input"
                                                                                                                disabled={false}
                                                                                                            />
                                                                                                        </FormGroup>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 p-t-20">
                                                                                                <div>
                                                                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                                        <FormGroup>
                                                                                                            <Field
                                                                                                                label={<><span>Confirm Password</span><span className='formmandatorycolor'> *</span></>}
                                                                                                                type="password"
                                                                                                                name="confirmpassword"
                                                                                                                component={TextField}
                                                                                                                className="textfield__input"
                                                                                                                disabled={false}
                                                                                                            />
                                                                                                        </FormGroup>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                <div className="text-right mb-3 mr-2 mt-4">                                                                                    
                                                                                    <Button className="btn btn-pink mr-1 ml-1 w-15"
                                                                                        disabled={isUserEmpty || isValidating || !dirty ||
                                                                                            !!(errors.old_password && touched.old_password) ||
                                                                                            !!(errors.new_password && touched.new_password) ||
                                                                                            !!(errors.confirmpassword && touched.confirmpassword)} type="submit">Submit</Button>
                                                                                    <Link to="/user">
                                                                                        <Button className="btn btn-default mr-1 ml-1 w-15">Cancel</Button>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    )
                                                                }
                                                                }
                                                            />
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* : <SpinnerLoader /> */}
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {

    }
}

const mapDispatchToProps = (dispatch?: any) => {
    return {
        postChangePassword: (postUserInfo?: any) => dispatch(ReqChangePassword(postUserInfo)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
// export default UserProfile;
// export default connect(
//     { ChangePasswordPost }
// )(UserProfile);
