import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import { fetchQuizQuestionResponses } from "../../store/dashboard/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import {
  stopYearData,
  stopSubComponentYear,
} from "../../store/profile/Actions";
import CommonLoader from "../../components/CommonLoader";

export type PostsFetchQuizBarProps = {
  getChangeYearData?: any;
  stopYearDate?: any;
  stopYearData: () => any;
  stopSubComponentYear: (postValue: any) => any;
  fetchQuizQuestionResponses: (postValue: any) => any;
  getfetchQuizDetails: any;
  getDurationList: any;
  loading?: any;
  isAdminQuizReportLoader?: boolean;
};
interface StateTypes {
  quizChartBarFilter: any;
  quizRecord?: any;
  academicYear?: any;
}

export class QuizQuestionResponses extends Component<
  PostsFetchQuizBarProps,
  StateTypes
> {
  constructor(props: any) {
    super(props);
    this.state = {
      quizChartBarFilter: "This Year",
      academicYear: null,
    };
  }
  componentDidMount(): void {
    this.getCurrectYear();
  }
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.getCurrectYear(this.props.getChangeYearData);
      const postValue: any = {
        status: true,
      };
      this.props.stopSubComponentYear(postValue);
    }
  }
  componentDidUpdate() {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.props.stopYearData();
    }
  }
  getCurrectYear(getYear?: any) {
    window.scrollTo(0, 0);
    if (getYear && getYear !== undefined && getYear !== null) {
      this.setState({ academicYear: getYear });
      this.getFetchQuizChartBar(getYear);
    } else {
      this.setState({ academicYear: this.props.getChangeYearData });
      this.getFetchQuizChartBar(this.props.getChangeYearData);
    }
  }
  getFetchQuizChartBar(getYear?: any) {
    const loadMoreType = {
      filter: "This Year",
      academic_year: getYear,
    };
    this.props.fetchQuizQuestionResponses(loadMoreType).then((res: any) => {
      const { getfetchQuizDetails } = this.props;
      if (
        getfetchQuizDetails &&
        getfetchQuizDetails !== undefined &&
        getfetchQuizDetails !== null &&
        getfetchQuizDetails.length > 0
      ) {
        this.getQuizQuestion(getfetchQuizDetails);
        this.setState({ quizChartBarFilter: "This Year" });
      } else {
        this.setState({ quizRecord: [] });
        this.setState({ quizChartBarFilter: "This Year" });
      }
    });
  }
  FilterQuizChartBar = (e: any) => {
    const { value } = e.target;
    const { academicYear } = this.state;
    if (value) {
      const loadMoreType = {
        filter: value,
        academic_year: academicYear,
      };
      this.props.fetchQuizQuestionResponses(loadMoreType).then((res: any) => {
        const { getfetchQuizDetails } = this.props;
        if (
          getfetchQuizDetails &&
          getfetchQuizDetails !== undefined &&
          getfetchQuizDetails !== null &&
          getfetchQuizDetails.length > 0
        ) {
          this.getQuizQuestion(getfetchQuizDetails);
        } else {
          this.setState({ quizRecord: [] });
        }
      });
      this.setState({ quizChartBarFilter: value });
    }
  };
  getQuizQuestion(getfetchQuizDetails: any) {
    let getSchoolName: any = [];
    let getColorCode: any = [];
    let getQuizResponse: any = [];
    if (getfetchQuizDetails) {
      getSchoolName = [];
      getColorCode = [];
      getQuizResponse = [];
      getfetchQuizDetails.forEach((quiz: any, index: any) => {
        getSchoolName.push(quiz.school_name);
        getColorCode.push(quiz.color_code);
        getQuizResponse.push(quiz.quiz_response);
      });
      const PostValue: any = {
        getSchoolName: getSchoolName,
        getColorCode: getColorCode,
        getQuizResponse: getQuizResponse,
      };
      if (PostValue) {
        this.setState({ quizRecord: PostValue });
      }
    }
  }
  refreshQuizQuestion = () => {
    const { academicYear } = this.state;
    this.getFetchQuizChartBar(academicYear);
  };
  render() {
    const { getDurationList, isAdminQuizReportLoader } = this.props;
    const { quizRecord } = this.state;
    const loadingDashboard = {
      display: isAdminQuizReportLoader ? "block" : "none",
    };
    return (
      <div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card card-topline-red">
              <div className="overlay" style={loadingDashboard}>
                <div className="custom-spinner-individual"></div>
              </div>
              <div className="card-head">
                <header>Quiz Question Responses</header>
                <div className="tools">
                  <div className="d-flex">
                    <div>
                      {getDurationList ? (
                        <select
                          name="classlist"
                          value={this.state.quizChartBarFilter}
                          className="form-control"
                          onChange={this.FilterQuizChartBar}
                        >
                          {getDurationList.map((items: any) => (
                            <option value={items.value}> {items.value} </option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                    <div className="ml-2 mt-2">
                      <span
                        className="fa fa-repeat btn-color box-refresh pointer"
                        onClick={this.refreshQuizQuestion}
                      ></span>
                      <span className="t-collapse btn-color fa fa-chevron-down pointer"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body no-padding height-9">
                <div className="row">
                  {quizRecord &&
                  quizRecord !== null &&
                  quizRecord.length !== 0 ? (
                    <Bar
                      data={{
                        labels: quizRecord.getSchoolName,
                        datasets: [
                          {
                            label: "Number of Quiz Taken",
                            backgroundColor: quizRecord.getColorCode,
                            borderColor: quizRecord.getColorCode,
                            borderWidth: 2,
                            data: quizRecord.getQuizResponse,
                          },
                        ],
                      }}
                      height={90}
                      options={{
                        legend: {
                          display: true,
                          position: "top" as const,
                        },
                        title: {
                          display: true,
                          text: "",
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true,
                                steps: 10,
                                stepValue: 5,
                                userCallback: function (
                                  label: any,
                                  index: any,
                                  labels: any
                                ) {
                                  if (Math.floor(label) === label) {
                                    return label;
                                  }
                                },
                              },
                            },
                          ],
                          xAxes: [
                            {
                              barPercentage: 0.1,
                              gridLines: {
                                display: false,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  ) : (
                    <CommonLoader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={loadingDashboard}><SpinnerLoader /></div> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isAdminQuizReportLoader: state.dashboard.isAdminQuizReportLoader,
    getChangeYearData: state.profile.getYear,
    stopYearDate: state.profile.yearStatusFails,
    getfetchQuizDetails: state.dashboard.quizQuestionResponses,
    getDurationList: state.profile.getDuration,
  };
};

export default connect(mapStateToProps, {
  fetchQuizQuestionResponses,
  stopYearData,
  stopSubComponentYear,
})(QuizQuestionResponses);
