import React from 'react';
import { Link } from 'react-router-dom';
import history from '../History';
interface BreadCrumbProps {
    titleName: string[],
    homeName?: string[],
    url?: string[],
    baseName?: string[],
    baseURL?: string[],
    mainPageTitle?: string[],
    schoolStatus?: any
}

export default class BreadCrumb extends React.Component<BreadCrumbProps> {
    onCheckSchoolName(status: boolean) {
        this.props.schoolStatus(this.props.baseName)
    }
    render() {
        return (
            <div>
                <div className="page-bar">
                    <div className="page-title-breadcrumb">
                        <div className="pull-left">
                            <div className="page-title">{this.props.titleName}</div>
                        </div>
                        <ol className="breadcrumb page-breadcrumb pull-right">
                            <li><i className="fa fa-home"></i>
                                &nbsp;<Link className="parent-item" to={`/${this.props.url}`}>
                                    {this.props.homeName}
                                </Link>
                                &nbsp;<i className="fa fa-angle-right" style={{ display: this.props.mainPageTitle ? "inline-block" : "none" }}></i>
                            </li>
                            <li style={{ display: this.props.baseName && this.props.baseName.length !== 0 ? "inline-block" : "none" }}>
                                <Link className="parent-item" to={`/${this.props.baseURL}`} onClick={() => this.onCheckSchoolName(true)}>{this.props.baseName}</Link>
                                &nbsp;<i className="fa fa-angle-right"></i>
                            </li>
                            <li className="active">{this.props.mainPageTitle}</li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}
