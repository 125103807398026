import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TeacherPerformanceCount } from '../../store/teacher/Actions';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import Cookies from 'universal-cookie';
import { stopYearData } from '../../store/profile/Actions'

interface TeacherPerformanceType {
	getChangeYearData?:any;
	stopYearDate?:any;
    TeacherPerformanceCount:(postValue:any)=>void;
    stopYearData:()=>any;
    teacherPerformance?:any;
    getTeacherDetails?:any,
    loading?:any
}
interface StatuTypes {
    academicYear:any;
}
class ViewUserDetails extends Component<TeacherPerformanceType, StatuTypes> {
    cookies:any = new Cookies();
    constructor(props: any) {
        super(props);
        this.state = {
			academicYear:null
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear()
    }
    // This is life cycle is initial load  component Will Update
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}
    // This is life cycle is initial load  component Did Update
	componentDidUpdate() {
		const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getViewUserDetails(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.getViewUserDetails(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}

    getViewUserDetails(getYear?:any){
        const { getTeacherDetails } = this.props;
        const schoolId:any = this.cookies.get('ACADEMICSCHOOLID')
        if(schoolId){
            const postValue:any = {
                academic_year:getYear,
                teacher_id: getTeacherDetails,
                school_id:schoolId
            }
            this.props.TeacherPerformanceCount(postValue)
        }else{
            const postValue:any = {
                academic_year:getYear,
                teacher_id: getTeacherDetails
            }
            this.props.TeacherPerformanceCount(postValue)
        }
    }
    render() {
        const { teacherPerformance, loading } =  this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        const getTeacherInfo:any = localStorage.getItem('TeacherDetails')
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-topline-red">
                            <div className="card-body no-padding height-9">
                                <div className="row">
                                {getTeacherInfo && teacherPerformance ?
                                    <div className="col-md-3">
                                        <div className="row  mt-2">
                                            <div className="ml-3 d-flex flex-wrap">
                                                <div>
                                                    <button className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ml-3 btn-pink">
                                                        <span>{getTeacherInfo.charAt(0)}</span>											
                                                    </button>
                                                </div>
                                                <div>
                                                    <div className="text-center">
                                                    <h3 className="my-0">{getTeacherInfo}</h3>
                                                        <span>( Teacher )</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :null}
                                {teacherPerformance ?
                                teacherPerformance.map((items:any)=>(
                                    <div className="col-md-3 text-center">
                                            <h3>{items.count}</h3>
                                            <h4>{items.title}</h4>
                                        </div>
                                    ))
                                :null}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return{
        loading:state.teacher.loading,
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
        teacherPerformance:state.teacher.getTeacherPerformanceCount
    }
}
export default connect(mapStateToProps, {TeacherPerformanceCount, stopYearData})(ViewUserDetails);
