import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';
import { RootState, RootActions } from '../Index';
import { AxiosResponse } from 'axios';
import history from '../../History';
import { UserType, UserActionTypes } from './Type';
import { notificationMsg } from '../../services/Constants'
import toaster from "toasted-notes";
import { baseAPI, baseAPIAuth } from '../../Service';
import { USERNAMENAGE, DashboardAPI } from '../../services/Config'
import { LoadMoreType } from '../../components/type';

//Implement Thunk middle ware
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootActions>;

// Download user manual
//DOWNLOAD FILE
interface getDownloadUserGuideFile {
    type: UserActionTypes.DOWNLOAD_USER_GUIDE;
}

interface getDownloadUserGuideFileSuccess {
    type: UserActionTypes.DOWNLOAD_USER_GUIDE_SUCCESS;
    payload: any;
}

interface getDownloadUserGuideFileFail {
    type: UserActionTypes.DOWNLOAD_USER_GUIDE_FAIL
    payload: any
}
export const reqUserGuideDownloadFile = (): ThunkResult<void> => async dispatch => {
    handleUserGuideDownloadFileFormat(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(DashboardAPI.downloadUserGuide,
            // { headers: { "Authorization": localStorage.getItem('token') } ,responseType: 'arraybuffer'});
            { headers: { "Authorization": localStorage.getItem('token') } });
        if (response.data) {
            // console.log("file in action:", response.data)
            handleUserGuideDownloadFileFormatSuccess(dispatch, response.data);
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleUserGuideDownloadFileFormatFail(dispatch, response.data);
        }
    } catch (e) {
        handleUserGuideDownloadFileFormatFail(dispatch, e);
    }
};


export const handleUserGuideDownloadFileFormat = (dispatch: Dispatch<getDownloadUserGuideFile>) => {
    dispatch({ type: UserActionTypes.DOWNLOAD_USER_GUIDE });
};

export const handleUserGuideDownloadFileFormatSuccess = (
    dispatch: Dispatch<getDownloadUserGuideFileSuccess>,
    response: any
) => {
    dispatch({
        type: UserActionTypes.DOWNLOAD_USER_GUIDE_SUCCESS,
        payload: response,
        downloadUserGuideFiles: response
    });

};

const handleUserGuideDownloadFileFormatFail = (dispatch: Dispatch<getDownloadUserGuideFileFail>, response: any) => {
    dispatch({
        type: UserActionTypes.DOWNLOAD_USER_GUIDE_FAIL,
        payload: response,
        errors: response.data
    });
};

// Fetch user management
interface FetchUser {
    type: UserActionTypes.FETCH_USER;
}

interface FetchUserSuccess {
    type: UserActionTypes.FETCH_USER_SUCCESS;
    payload: UserType;
}

interface FetchUserFail {
    type: UserActionTypes.FETCH_USER_FAIL;
}
export const fetchUserPost = (loadMoreType: LoadMoreType): ThunkResult<void> => async dispatch => {
    handleFetchUser(dispatch);
    try {
        const response: AxiosResponse<UserType> = await baseAPIAuth.get(USERNAMENAGE.GETUSER, {
            params: loadMoreType,
            headers: { "Authorization": localStorage.getItem('token') }
        });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            handleFetchUserSuccess(dispatch, response.data);
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleFetchUserFail(dispatch);
        }
    } catch (e) {
        handleFetchUserFail(dispatch);
    }
};

export const handleFetchUser = (dispatch: Dispatch<FetchUser>) => {
    dispatch({ type: UserActionTypes.FETCH_USER });
};

export const handleFetchUserSuccess = (
    dispatch: Dispatch<FetchUserSuccess>,
    response: any
) => {
    dispatch({
        type: UserActionTypes.FETCH_USER_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });

};

export const handleFetchUserFail = (dispatch: Dispatch<FetchUserFail>) => {
    dispatch({
        type: UserActionTypes.FETCH_USER_FAIL
    });
};

// This is function used to change password
interface postChangePassword {
    type: UserActionTypes.POST_UPDATE_USER_DATA;
}

interface postChangePasswordSuccess {
    type: UserActionTypes.POST_UPDATE_USER_DATA_SUCCESS;
    payload: UserType;
}

interface postChangePasswordFail {
    type: UserActionTypes.POST_UPDATE_USER_DATA_FAIL;
    payload: any;
}
export const ReqChangePassword = (getValues: any): ThunkResult<void> => async dispatch => {
    handlePostChangePassword(dispatch);
    try {
        const response: AxiosResponse<UserType> = await baseAPIAuth.put(USERNAMENAGE.changePasswordAPI, getValues, {
            headers: { "Authorization": localStorage.getItem('token') }
        });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handlePostChangePasswordSuccess(dispatch, response.data);
            history.push('/dashboard');
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handlePostChangePasswordFail(dispatch, response.data);
        }
    } catch (e) {
        handlePostChangePasswordFail(dispatch, e);
    }
};

export const handlePostChangePassword = (dispatch: Dispatch<postChangePassword>) => {
    dispatch({ type: UserActionTypes.POST_UPDATE_USER_DATA });
};

export const handlePostChangePasswordSuccess = (
    dispatch: Dispatch<postChangePasswordSuccess>,
    response: any
) => {
    dispatch({
        type: UserActionTypes.POST_UPDATE_USER_DATA_SUCCESS,
        payload: response,
    });

};

export const handlePostChangePasswordFail = (dispatch: Dispatch<postChangePasswordFail>,
    response: any) => {
    dispatch({
        type: UserActionTypes.POST_UPDATE_USER_DATA_FAIL,
        payload: response
    });
};

// Fetch category

interface FetchCategory {
    type: UserActionTypes.FETCH_USER_CATEGORY;
}

interface FetchCategorySuccess {
    type: UserActionTypes.FETCH_USER_SUCCESS_CATEGORY;
    payload: any;
}

interface FetchCategoryFail {
    type: UserActionTypes.FETCH_USER_FAIL_CATEGORY;
}

export const getAllSchoolNameList = (): ThunkResult<void> => async dispatch => {
    handleFetchCategory(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(USERNAMENAGE.GETSCHOOLCATEGORY,
            {
                headers: { "Authorization": localStorage.getItem('token') }
            });
        handleFetchCategorySuccess(dispatch, response.data);
    } catch (e) {
        handleFetchCategoryFail(dispatch);
    }
};

// export const setInstitutionDetailsA = (data: any) => {
//     console.log(data, 'action')
//     return {
//         type: 'POST_INSTITUTION_DETAILS',
//         payload: data
//     };
// };

export const handleAddInstitution = (dispatch: any) => {
    // console.log(dispatch,'dispatch')
    dispatch({ type: UserActionTypes.POST_INSTITUTION_DETAILS });
};
export const handleAddInstitutionSuccess = (
    dispatch: Dispatch<any>,
    response: any
) => {
    dispatch({
        type: UserActionTypes.POST_INSTITUTION_SUCCESS,
        payload: response
    });
    
};

export const handleAddInstitutionFail = (dispatch: Dispatch<any>, response: any) => {
    dispatch({
        type: UserActionTypes.POST_INSTITUTION_FAILURE, payload: response
    });
};

export const setInstitutionDetailsA = (data:any): ThunkResult<void> => async dispatch => {
    // handleAddInstitution(dispatch);
    
    try {
        // const response: any = data
       
        if(data) {
            handleAddInstitutionSuccess(dispatch, data);
           
        } else {
            handleAddInstitutionFail(dispatch, data);
        }
    } catch (e) {
        // handleAddInstitutionFail(dispatch, e);
    }
};

export const getInstitutionDetailsA = () => {

    return {
        type: 'GET_INSTITUTION_DETAILS',

    };
};



export const handleFetchCategory = (dispatch: Dispatch<FetchCategory>) => {
    dispatch({ type: UserActionTypes.FETCH_USER_CATEGORY });
};

export const handleFetchCategorySuccess = (
    dispatch: Dispatch<FetchCategorySuccess>,
    response: any
) => {
    dispatch({
        type: UserActionTypes.FETCH_USER_SUCCESS_CATEGORY,
        payload: response.data
    });
};

export const handleFetchCategoryFail = (dispatch: Dispatch<FetchCategoryFail>) => {
    dispatch({
        type: UserActionTypes.FETCH_USER_FAIL_CATEGORY
    });
};

// Fetch User Management Id
interface FetchUserId {
    type: UserActionTypes.FETCH_USER_ID;
}

interface FetchUserSuccessId {
    type: UserActionTypes.FETCH_USER_SUCCESS_ID;
    payload: UserType;
}

interface FetchUserFailId {
    type: UserActionTypes.FETCH_USER_FAIL_ID;
}

export const fetchUserPostId = (id: string): ThunkResult<void> => async dispatch => {
    handleFetchUserId(dispatch);
    try {
        const response: AxiosResponse<UserType> = await baseAPIAuth.get(`/auth/user/?id=${id}`,
            { headers: { "Authorization": localStorage.getItem('token') } });
        handleFetchUserSuccessId(dispatch, response.data);
    } catch (e) {
        handleFetchUserFailId(dispatch);
    }
};

export const handleFetchUserId = (dispatch: Dispatch<FetchUserId>) => {
    dispatch({ type: UserActionTypes.FETCH_USER_ID });
};

export const handleFetchUserSuccessId = (
    dispatch: Dispatch<FetchUserSuccessId>,
    response: UserType
) => {
    dispatch({
        type: UserActionTypes.FETCH_USER_SUCCESS_ID,
        payload: response
    });

};

export const handleFetchUserFailId = (dispatch: Dispatch<FetchUserFailId>) => {
    dispatch({
        type: UserActionTypes.FETCH_USER_FAIL_ID
    });
};

// Add Classes
interface AddUser {
    type: UserActionTypes.ADD_USER;
}

interface AddUserSuccess {
    type: UserActionTypes.ADD_USER_SUCCESS;
    payload: UserType;
}

interface AddUserFail {
    type: UserActionTypes.ADD_USER_FAIL;
    payload: any;
}

export const AddUserPost = (userManage: UserType): ThunkResult<void> => async dispatch => {
    handleAddUser(dispatch);
    try {
        const response: AxiosResponse<UserType> = await baseAPIAuth.post(USERNAMENAGE.USERMANAGE, userManage,
            { headers: { "Authorization": localStorage.getItem('token') } });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleAddUserSuccess(dispatch, response.data);
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleAddUserFail(dispatch, response.data);
        }

    } catch (e) {
        handleAddUserFail(dispatch, e);
    }
};

export const handleAddUser = (dispatch: Dispatch<AddUser>) => {
    dispatch({ type: UserActionTypes.ADD_USER });
};

export const handleAddUserSuccess = (
    dispatch: Dispatch<AddUserSuccess>,
    response: UserType
) => {
    dispatch({
        type: UserActionTypes.ADD_USER_SUCCESS,
        payload: response
    });
    history.push('/user');
};

export const handleAddUserFail = (dispatch: Dispatch<AddUserFail>, response: any) => {
    dispatch({
        type: UserActionTypes.ADD_USER_FAIL,
        payload: response
    });
};

// Edit User management
interface EditUser {
    type: UserActionTypes.EDIT_USER;
}

interface EditUserSuccess {
    type: UserActionTypes.EDIT_USER_SUCCESS;
    payload: UserType;
}

interface EditUserFail {
    type: UserActionTypes.EDIT_USER_FAIL;
    payload: any;
}

export const EditUserPost = (userManage: UserType): ThunkResult<void> => async dispatch => {
    handleEditUser(dispatch);
    try {
        const response: AxiosResponse<UserType> = await baseAPIAuth.put(`/auth/user/?id=${userManage.ldap_id}`, userManage,
            { headers: { "Authorization": localStorage.getItem('token') } });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true || getResponse.status === "true") {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleEditUserSuccess(dispatch, response.data);
            history.push('/user');
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleEditUserFail(dispatch, response.data);
        }
    } catch (e) {
        handleEditUserFail(dispatch, e);
    }
};

export const handleEditUser = (dispatch: Dispatch<EditUser>) => {
    dispatch({ type: UserActionTypes.EDIT_USER });
};

export const handleEditUserSuccess = (
    dispatch: Dispatch<EditUserSuccess>,
    response: UserType
) => {
    dispatch({
        type: UserActionTypes.EDIT_USER_SUCCESS,
        payload: response
    });
    history.push('/user');
};

export const handleEditUserFail = (dispatch: Dispatch<EditUserFail>,
    response: any) => {
    dispatch({
        type: UserActionTypes.EDIT_USER_FAIL,
        payload: response
    });
};

// DELETE USER MANAGE

interface DeleteUser {
    type: UserActionTypes.DELETE_USER;
}

interface DeleteUserSuccess {
    type: UserActionTypes.DELETE_USER_SUCCESS;
    payload: UserType;
}

interface DeleteUserFail {
    type: UserActionTypes.DELETE_USER_FAIL;
}

export const deletePost = (deletedId: any): ThunkResult<void> => async dispatch => {
    const getDeleteValue = { "is_active": deletedId.isActive }
    handleDeleteUser(dispatch);
    try {
        const response: AxiosResponse<UserType> = await baseAPIAuth.patch(`/auth/user/?id=${deletedId.ldapId}`, getDeleteValue,
            { headers: { "Authorization": localStorage.getItem('token') } });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleDeleteUserSuccess(dispatch, response.data);
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleDeleteUserFail(dispatch);
        }

    } catch (e) {
        handleDeleteUserFail(dispatch);
    }
};

const handleDeleteUser = (dispatch: Dispatch<DeleteUser>) => {
    dispatch({ type: UserActionTypes.DELETE_USER });
};

const handleDeleteUserSuccess = (
    dispatch: Dispatch<DeleteUserSuccess>,
    response: UserType
) => {
    dispatch({ type: UserActionTypes.DELETE_USER_SUCCESS, payload: response });
};
const handleDeleteUserFail = (dispatch: Dispatch<DeleteUserFail>) => {
    dispatch({ type: UserActionTypes.DELETE_USER_FAIL });
};


// Clear All Data
interface FetchResetUserManage {
    type: UserActionTypes.Reset_UserManage_Details
}

export const ResetUserManage = (): ThunkResult<void> => async dispatch => {
    handleResetUserManage(dispatch);
}

export const handleResetUserManage = (dispatch: Dispatch<FetchResetUserManage>) => {
    dispatch({ type: UserActionTypes.Reset_UserManage_Details });
};
export type UserAction =
    | FetchUserSuccess
    | FetchUserFail
    | FetchUserSuccessId
    | FetchUserFailId
    | EditUserSuccess
    | DeleteUserSuccess
    | AddUserSuccess
    | AddUserFail;