import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchStudentProfileTopic } from '../../../store/student/Actions';
import history from '../../../History';
import { Link } from 'react-router-dom';
import { getFeedBackReport } from '../../../store/profile/Actions';
import SpinnerLoader from '../../../components/spinner/SpinnerLoader';
import { stopYearData, stopSubComponentYear } from '../../../store/profile/Actions'

type propsTyps = {
    getChangeYearData?:any;
	stopYearDate?:any;
    getStudentInfoDetails?: any;
    stopSubComponentYear:(postValue?: any)=>any;
    fetchStudentProfileTopic: (postValue: any) => any;
    getFeedBackReport:(postValue:any) => any;
    stopYearData:()=>any;
    loading?: any
    studentProfileTopic: any;
    loginProfile?: any;
    subComponentStatus?:any
}
export class StudentProfileTopic extends Component<propsTyps> {

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.UpdateYearDate();
    }

    componentWillUpdate(nextProps:any, nextState:any) {
        const {stopYearDate, subComponentStatus} = this.props;
        if(subComponentStatus === true){
			this.props.stopSubComponentYear()
            this.UpdateYearDate(this.props.getChangeYearData)
		}else if(stopYearDate === true){
            this.UpdateYearDate(this.props.getChangeYearData)
        }
    }

    componentDidUpdate() {
        const {stopYearDate, subComponentStatus} = this.props;
        if(subComponentStatus === true){
			this.props.stopSubComponentYear()
		}else if(stopYearDate === true){
            this.props.stopYearData()
        }
    }

    UpdateYearDate(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.getStudentFullDetails(getYear)
        }else {
            this.getStudentFullDetails(this.props.getChangeYearData)
        }
    }

    getStudentFullDetails(getYear?:any) {
        let getStudentId: any = this.props.getStudentInfoDetails;
            const { loginProfile } = this.props;
            if (loginProfile) {
                if (loginProfile.usertype === 1) {
                    if (getStudentId) {
                        let postValue: any = {
                            academic_year:getYear,
                            class_id: getStudentId.class_id,
                            card_id: getStudentId.card_id,
                            school_id: getStudentId.school_id
                        }
                        this.props.fetchStudentProfileTopic(postValue);
                    }
                } else if (loginProfile.usertype === 2 || loginProfile.usertype === 3) {
                    let postValue: any = {
                        academic_year:getYear,
                        class_id: getStudentId.class_id,
                        card_id: getStudentId.card_id
                    }
                    this.props.fetchStudentProfileTopic(postValue);
                }
            }
    }
    getTopicDetails(getValue?: any, getAllValue?: any) {
        let studentDetails: any = this.props.getStudentInfoDetails;
        // console.log(studentDetails.class_id,'studentDetails.class_id')
        history.push({
            pathname: `/topics/${getValue}`
        });
        let postData: any = {
            feedback: getValue,
            subject_id: getAllValue.subject_id,
            school_id: studentDetails.school_id,
            pageName: 'View Student',
            card_id: studentDetails.card_id,
            class_id: studentDetails.class_id
        }
        this.props.getFeedBackReport(postData)
    }
    getQuizzesReport = (getValue: any) => {
        if (getValue) {
            history.push({
                pathname: `/quizzes_report/${getValue.quiz_id}`,
            });
        }
    }
    render() {
        const { studentProfileTopic, loading } = this.props;
    const loadingTextCSS = { display: loading ? "block" : "none" };
        return (
            <div>
                 {/* This is the part of show topics details list */}
                 <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-topline-red">
                                        <div className="card-body no-padding height-9">
                                            <div className="row">
                                                <div className="col-md-12 center">
                                                    <h4>Topics</h4>
                                                </div>
                                            </div>
                                            {/* <!-- start chart--> */}

                                            <div className="row">
                                                {studentProfileTopic ?
                                                    studentProfileTopic.map((items: any) => (
                                                        <div className="col-md-4 mb-3">
                                                            <h4>{items.subject_name}</h4>
                                                            <div className="row p-3">
                                                                {items.class_list ?
                                                                    items.class_list.map((classData: any) => (
                                                                        <div className="d-flex flex-wrap ml-3">
                                                                            {classData.graph_data ?
                                                                                classData.graph_data.map((graphTopic: any) => (
                                                                                    <Link to={'#'} onClick={(e: any) => this.getTopicDetails(graphTopic.topics, items)}>
                                                                                        <div className="mt-sm-3 ml-1">
                                                                                            <div className="student-status-color-good
                                                                                        mr-2" style={{ background: graphTopic.color_code }} >
                                                                                                <div className="boxsize">
                                                                                                    <p className="WordCrop">{graphTopic.topics}</p>
                                                                                                    <span>{graphTopic.question_performance} %</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Link>
                                                                                ))
                                                                                : null}
                                                                        </div>
                                                                    ))
                                                                    : null}

                                                            </div>
                                                        </div>
                                                    ))
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return{
        loading: state.questionset.loading,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
		subComponentStatus:state.profile.subComponentStart,
        getStudentInfoDetails: state.profile.AttendanceStudentView,
        studentProfileTopic: state.student.studentProfileTopic,
        loginProfile: state.profile.profileData
    }
}
const mapDispatchToProps = {
    fetchStudentProfileTopic, 
    getFeedBackReport, 
    stopYearData, 
    stopSubComponentYear
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfileTopic)
