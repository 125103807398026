import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import {
  fetchGradePost,
  fetchMyClassListGet,
} from "../../store/classes/Actions";
import { GetCorrectSubject } from "../../store/subject/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import {
  addQuestion,
  getTeacherSubjectList,
} from "../../store/question/Actions";
import { fetchGetAllClassList } from "../../store/diary/Actions";
import "./QuestionStyle.scss";
import { stopYearData } from "../../store/profile/Actions";
import UploadCsvFile from "./UploadCsvFile";
import {reqDownloadFile} from '../../store/question/Actions';
import * as FileSaver from 'file-saver';

export type OwnQuestSetProps = {
  loading: boolean;
  getChangeYearData?: any;
  stopYearDate?: any;
  loadingQuestion: boolean;
  addQuestion: (postQuestion: any) => void;
  fetchGradePost: (postValue: any) => void;
  GetCorrectSubject: (postValue: any) => void;
  fetchGetAllClassList: (postValue: any) => any;
  getTeacherSubjectList: (getList: any) => any;
  fetchMyClassListGet: () => any;
  reqDownloadFile:()=>any;
  stopYearData: () => any;
  schoolAdminClass: any;
  getClassList: any;
  getSubjectlist: any;
  getProfile: any;
  getCorrectClassList: any;
  getErrormessage: any;
  TeacherSubjectList: any;
  getDownloadFile:any;
};
interface StateTypes {
  SubjectList: any;
  ClassListSchoolAdmin: any;
  ClassListTeacher: any;
  classListStatus?: any;
  academicYear?: null;
  openCsvFileUpload?:any
}

export class UploadQuestion extends Component<OwnQuestSetProps, StateTypes> {
  getQuestionList: any = [];
  formikQuestion: any;
  constructor(props: any) {
    super(props);
    this.state = {
      SubjectList: [],
      ClassListSchoolAdmin: [],
      ClassListTeacher: [],
      classListStatus: false,
      academicYear: null,
      openCsvFileUpload:false
    };
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getCurrectYear();
  }
  
  onDownloadFile = () =>{
    const FileDownload = require("js-file-download");
    this.props.reqDownloadFile().then(()=>{
      var blob = new Blob([this.props.getDownloadFile], { type: 'application/zip' });
        FileSaver.saveAs(blob, 'questionset_template_and_help.zip');
    })
  }

  // This is life cycle is initial load  component Will Update
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.getCurrectYear(this.props.getChangeYearData);
    }
  }
  // This is life cycle is initial load  component Did Update
  componentDidUpdate() {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.props.stopYearData();
    }
  }
  // This is function is used get correct year
  getCurrectYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.getAddQuestion(getYear);
      this.setState({ academicYear: getYear });
    } else {
      this.getAddQuestion(this.props.getChangeYearData);
      this.setState({ academicYear: this.props.getChangeYearData });
    }
  }

  getAddQuestion(getYear?: any) {
    const { getProfile } = this.props;
    const postValue = {
      academic_year: getYear,
    };
    this.props.fetchGradePost(postValue);
    if (getProfile) {
      if (getProfile.usertype === 2) {
        this.props.fetchMyClassListGet();
      } else if (getProfile.usertype === 3) {
        this.getCommonData(getYear);
      }
    }
    this.onShowUserBasedClass();
  }
  getCommonData(getYear?: any) {
    const { getProfile } = this.props;
    if (getProfile) {
      const postValue = {
        academic_year: getYear,
        school_id: getProfile.school_id,
      };
      this.props.fetchGetAllClassList(postValue).then((res: any) => { });
    }
  }
  getschoolAdmin(getGradeId: any) {
    const { getProfile } = this.props;
    const { academicYear } = this.state;
    if (getProfile) {
      const postValue = {
        academic_year: academicYear,
        school_id: getProfile.school_id,
        grade_id: getGradeId,
      };
      this.props.getTeacherSubjectList(postValue).then((res: any) => {
        const { TeacherSubjectList } = this.props;
        if (
          TeacherSubjectList &&
          TeacherSubjectList.length > 0 &&
          TeacherSubjectList !== undefined
        ) {
          this.setState({
            SubjectList: TeacherSubjectList,
            classListStatus: false,
          });
          this.formikQuestion.setFieldValue("select_subject", "");
        } else {
          this.setState({ SubjectList: [], classListStatus: true });
          this.formikQuestion.setFieldError(
            "select_subject",
            "No matching records found"
          );
        }
      });
    }
  }
  getPostSubjectList = (e: any, getValue?: any) => {
    if (getValue) {
      this.getschoolAdmin(getValue);
    }
  };

  onShowUserBasedClass() {
    const getProfile = this.props.getProfile;
    if (getProfile) {
      if (getProfile.usertype === 1 || getProfile.usertype === 2) {
        this.setState({ ClassListSchoolAdmin: this.props.schoolAdminClass });
        if (this.state.SubjectList.length > 0) {
          this.setState({ classListStatus: false });
        } else {
          this.setState({ classListStatus: true });
        }
      } else if (getProfile.usertype === 3) {
        this.setState({ ClassListTeacher: this.props.schoolAdminClass });
        if (this.state.SubjectList.length > 0) {
          this.setState({ classListStatus: false });
        } else {
          this.setState({ classListStatus: true });
        }
      }
    }
  }

  onOpenUploadOption = () =>{
this.setState({openCsvFileUpload:!this.state.openCsvFileUpload})
  }
  render() {
    const { loading, loadingQuestion, getProfile } = this.props;
    const {academicYear} =this.state;

    const loadingQuestionFull = {
      display: loading || loadingQuestion ? "block" : "none",
    };
    const {
      SubjectList,
      ClassListSchoolAdmin,
      ClassListTeacher,
      classListStatus,
    } = this.state;
    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={["Upload Question Set"]}
                homeName={["Home"]}
                url={["dashboard"]}
                mainPageTitle={["Upload Question Set"]}
                baseName={["Question Set"]}
                baseURL={["question"]}
              />
              {SubjectList && getProfile && this.props.getClassList ? (
                <div className="row">
                  {/* This is button used to download and upload file */}
                  {/* Start */}
                  <div className="col-md-8"></div>
                  <div className="col-md-4 text-right mt-3">
                    <Button className="btn btn-pink mr-1 ml-1" title="Download CSV File Format" onClick={()=>this.onDownloadFile()}>
                        <i className="fa fa-download" aria-hidden="true"></i> &nbsp; Download
                    </Button>
                  </div>
                  {/* End */}
                  <div className="col-md-12">
                      <UploadCsvFile ClassListSchoolAdmin={ClassListSchoolAdmin}
                      getProfile={getProfile} getSubjectList={this.getPostSubjectList} ClassListTeacher={ClassListTeacher}
                      getCorrectClassList={this.props.getCorrectClassList} classListStatus={classListStatus} 
                      SubjectList={SubjectList} formikQuestion={this.formikQuestion} academicYear={academicYear}/>                      
                  </div>
                </div>
              ) : (
                <SpinnerLoader />
              )}
            </div>
          </div>
        </div>
        <div style={loadingQuestionFull}>
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.classes.loading,
    getChangeYearData: state.profile.getYear,
    stopYearDate: state.profile.yearStatusFails,
    loadingQuestion: state.questionset.loading,
    getClassList: state.classes.gradelist,
    getSubjectlist: state.subjects.category,
    getProfile: state.profile.profileData,
    schoolAdminClass: state.classes.my_class,
    getCorrectClassList: state.diary.gradelist,
    TeacherSubjectList: state.questionset.getTeacherSubjectList,
    getErrormessage: state.questionset.errors,
    getDownloadFile:state.questionset.downloadFiles
  };
};

export default connect(mapStateToProps, {
  fetchGradePost,
  GetCorrectSubject,
  addQuestion,
  fetchGetAllClassList,
  getTeacherSubjectList,
  fetchMyClassListGet,
  reqDownloadFile,
  stopYearData,
})(UploadQuestion);

