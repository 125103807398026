import React from 'react'
import { Field, Formik, Form } from "formik";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { TextField } from "formik-material-ui";
import { DropzoneArea } from "material-ui-dropzone";
import { getTeacherSubjectList, postUploadCSVFormat } from '../../store/question/Actions';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { UpdateQuestionValidation } from './QuestionValidation';

const useStyles = makeStyles(theme => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));
export interface stateTypes {
  question_name: any,
  select_class: any,
  select_subject: any,
}
const UploadCsvFile = (props?: any) => {
  const classes = useStyles();
  const { TeacherSubjectList } = props;
  const [uploadFile, setUploadFile] = React.useState<any>([])
  const [statuuploadFile, setStatuUploadFile] = React.useState<any>(false)
  const [SubjectList, setSubjectList] = React.useState([])
  const [classListStatus, setClassListStatus] = React.useState(false)
  const initialValues: stateTypes = {
    question_name: "",
    select_class: "",
    select_subject: "",
  }
  React.useEffect(()=>{
    setClassListStatus(true)
  },[])
  
  const handleChange = (files?: any) => {
    if (files.length) {
      const file = files[0]
      setUploadFile(file)
      setStatuUploadFile(true)
    }else{
      setUploadFile([])
      setStatuUploadFile(false)
    }

  }
  React.useEffect(()=>{
    if (
      TeacherSubjectList &&
      TeacherSubjectList.length > 0 &&
      TeacherSubjectList !== undefined
    ) {
      setSubjectList(TeacherSubjectList)
      setClassListStatus(false)
    } else {
      setSubjectList([])
      setClassListStatus(true)
    }
  },[TeacherSubjectList])

  const getPostSubjectList = (e: any, getValue?: any) => {
    if (getValue) {
      getschoolAdmin(getValue);
    }
  };

  const getschoolAdmin = (getGradeId: any) => {
    const { getProfile, academicYear } = props;
    if (getProfile) {
      const postValue = {
        academic_year: academicYear,
        school_id: getProfile.school_id,
        grade_id: getGradeId,
      };
      props.getTeacherSubjectList(postValue).then((res: any) => {
      });
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdateQuestionValidation}
      onSubmit={(values, actions) => {
        let fileInfo: any = uploadFile;
        const formData = new FormData();
        formData.append("school_id", `${parseInt(props.getProfile.school_id)}`);
        formData.append("academic_year", props.academicYear);
        formData.append("grade_id", `${parseInt(values.select_class)}`);
        formData.append("subject_id", `${parseInt(values.select_subject)}`);
        formData.append("question_name", values.question_name);
        formData.append("posted_by", `${props.getProfile.firstname} ${props.getProfile.lastname}`);
        formData.append("file", fileInfo);
        props.postUploadCSVFormat(formData)
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        isValidating,
        dirty,
        touched,
        handleReset,
        handleSubmit,
        setFieldValue,
      }) => {
        // this is the add question set validation message
        const isQuestionEmpty =
          !values.question_name ||
          !values.select_class ||
          !values.select_subject;
        return (
          <Form>
            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Field
                    label={<><span>Question Set Name</span><span className='formmandatorycolor'> *</span></>}
                    type="text"
                    name="question_name"
                    component={TextField}
                    className="textfield__input"
                    disabled={false}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup className="w-100 mb-4">
                  {props.ClassListSchoolAdmin &&
                    props.getProfile.usertype === 2 ? (
                    <Field
                      label={<><span>Select Class</span><span className='formmandatorycolor'> *</span></>}
                      name="select_class"
                      select
                      component={TextField}
                      disabled={false}
                      onChange={(e: any, value: any) => {
                        setFieldValue(
                          "select_class",
                          e.target.value
                        );
                        getPostSubjectList(e, e.target.value);
                      }}
                    >
                      {props.ClassListSchoolAdmin.map((item: any) => (
                        <MenuItem value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  ) : null}
                  {props.ClassListTeacher &&
                    props.getProfile.usertype === 3 ? (
                    <Field
                      label={<><span>Select Class</span><span className='formmandatorycolor'> *</span></>}
                      name="select_class"
                      select
                      component={TextField}
                      disabled={false}
                      onChange={(e: any, value: any) => {
                        setFieldValue(
                          "select_class",
                          e.target.value
                        );
                        getPostSubjectList(e, e.target.value);
                      }}
                    >
                      {props.getCorrectClassList.map(
                        (item: any) => (
                          <MenuItem value={item.id}>
                            {item.grade_standard}
                          </MenuItem>
                        )
                      )}
                    </Field>
                  ) : null}
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup className="w-100 mb-4">
                  <Field
                    label={<><span>Select Subject</span><span className='formmandatorycolor'> *</span></>}
                    name="select_subject"
                    select
                    component={TextField}
                    disabled={classListStatus}
                  >
                    {SubjectList.map((item: any) => (
                      <MenuItem value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormGroup>
              </div>

              <div className="col-md-12 mt-3">
                <DropzoneArea onChange={(event) => handleChange(event)} 
                acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                  // showPreviews={false}
                  maxFileSize={5000000}
                  filesLimit={1}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText="Selected files"

                />
              </div>
            </div>
            <div className="text-right mb-3 mr-2 mt-4">
              <Button
                className="btn btn-pink mr-1 ml-1"
                type="submit"
                disabled={
                  isQuestionEmpty ||
                  isValidating ||
                  !dirty ||
                  !!(
                    errors.question_name &&
                    touched.question_name
                  ) ||
                  !!(
                    errors.select_class && touched.select_class
                  ) ||
                  !!(
                    errors.select_subject &&
                    touched.select_subject
                  )||
                  !statuuploadFile
                }
              >
                Submit
              </Button>
              <Link to={"/question"}>
                <Button className="btn btn-default mr-1 ml-1 ">
                  Cancel
                </Button>
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}


const mapStateToProps = (state: any) => {
  return {
    TeacherSubjectList: state.questionset.getTeacherSubjectList,
    getChangeYearData: state.profile.getYear,
  }
}

const mapDispatchToProps = (dispatch?: any) => {
  return {
    getTeacherSubjectList: (postUserInfo?: any) => dispatch(getTeacherSubjectList(postUserInfo)),
    postUploadCSVFormat: (postQuestionData?: any) => dispatch(postUploadCSVFormat(postQuestionData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadCsvFile);
// export default UploadCsvFile;
