import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { fetchSubjectAnalysis, fetchStandardAnalysis } from '../../store/dashboard/Actions';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import { CommonState } from '../../components/type';
import CommonLoader from '../../components/CommonLoader';
import { fetchMyClassListGet } from '../../store/classes/Actions';
import { fetchProfilePost, stopYearDataDashBoardSchoolChart } from '../../store/profile/Actions';

export type PostsSchoolBarProps = {
	getChangeYearData?:any;
	fetchGetAllClassList: (postPage: any) => any;
	fetchSubjectAnalysis:(getValue:any) => any;
	fetchStandardAnalysis:(postValue:any) => any;
	fetchMyClassListGet:(postValue?:any) => any;
	fetchProfilePost:() => any;
	stopYearDataDashBoardSchoolChart:() => any;
	getGradeOnly?:any;
	getAllClassList?:any;
	getSubjectAnalysis?:any;
	getStandardAnalysis?:any;
	loading?: boolean;
	getSchoolId?:any;
	loginProfile?: any;
	loadingProfile?:boolean;
	subjectAnalysisLoader?:boolean;
	isSubjectLoader?:boolean;
	standardSubjectLoader?:boolean;
	standardAnalysisLoader?:boolean
}
class Schoolstatuschart extends Component<PostsSchoolBarProps, CommonState> {
	constructor(props: any) {
		super(props);
		this.state = {
			page:1,
			page_no: 1,
			search: '',
			academicYear:null,
			class_id:null,
			SubjectAnalysis:[],
			StandardAnalysis:null,
			addClassName:'height300',
			addSAClassName:'height300',
			standarClassId:null
		}
	}
	componentDidMount(): void {
		window.scrollTo(0, 0);
		this.commonFun()
	}
	commonFun(getYear?:any){
			if(getYear && getYear !== undefined && getYear !== null){
				this.getSchoolStatusChart(getYear)
				this.setState({academicYear:getYear})
			}else {
				this.getSchoolStatusChart(this.props.getChangeYearData)
				this.setState({academicYear:this.props.getChangeYearData})
			}
		
	}
	getSchoolStatusChart(getYear?:any){
		const { loginProfile } = this.props;
		if(loginProfile && loginProfile !== undefined && loginProfile !== null){
			if(loginProfile.usertype === 1){
				this.academicSubjectAnalysis(getYear);
				this.academicStandardAnalysis(getYear)
			} else if(loginProfile.usertype === 2 || loginProfile.usertype === 3) {
				this.commonSubjectAnalysis(getYear)
				this.commonStandardAnalysis(getYear)
			}	
		}
	}
	componentWillUpdate(nextProps:any, nextState:any) {
		const {loadingProfile} = this.props;
		if(loadingProfile === true){
			this.commonFun(this.props.getChangeYearData)
		}
	}
	componentDidUpdate() {
		const {loadingProfile} = this.props;
		if(loadingProfile === true){
			this.props.stopYearDataDashBoardSchoolChart()
		}
	}

	commonSubjectAnalysis(getYear?:any){
		const { academicYear } = this.state;
		if(getYear && getYear !== undefined && getYear !== null){
			this.getSubjectAnalysisData(getYear)
		}else{
			this.getSubjectAnalysisData(academicYear)
		}
	}

	getSubjectAnalysisData(academicYear:any){
		let loadMoreType:any;
		if(academicYear){
			loadMoreType = {
				academic_year: academicYear
			  }
			this.props.fetchGetAllClassList(loadMoreType).then((res: any) => {	
				const { getAllClassList } = this.props;	
				if(getAllClassList && getAllClassList !== undefined && getAllClassList !== null){
					if(getAllClassList.length){
						const getPerformanace:any = {
							academic_year: academicYear,
							class_id:  getAllClassList[0].id
						  }
						this.props.fetchSubjectAnalysis(getPerformanace).then((res:any)=>{
						  const { getSubjectAnalysis } = this.props;
						  if(getSubjectAnalysis && getSubjectAnalysis !== undefined && getSubjectAnalysis !== null){
							  if(getSubjectAnalysis.length > 0){
								  this.setState({SubjectAnalysis:getSubjectAnalysis, class_id: getAllClassList[0].id})
							  }else {
								  this.setState({SubjectAnalysis:[], class_id: getAllClassList[0].id})
							  }
						  }
					  });
					}else {
						this.setState({SubjectAnalysis:[]})
					}	
				}		
			  }
		   )
		}
	}
	commonStandardAnalysis(getYear?:any){
		const { academicYear } = this.state;
		if(getYear && getYear !== undefined && getYear !== null){
			this.getStandardAnalysisData(getYear)
		}else{
			this.getStandardAnalysisData(academicYear)
		}
	}
	getStandardAnalysisData(academicYear?:any){
		this.props.fetchMyClassListGet().then((res:any)=>{
			const {getGradeOnly} = this.props;
			if(getGradeOnly){
				if(getGradeOnly.length){
					const getStandardAnalysis:any = {
						academic_year: academicYear,
						grade:getGradeOnly[0].value
					  }
					  this.props.fetchStandardAnalysis(getStandardAnalysis).then((res:any)=>{
						const { getStandardAnalysis } = this.props;
						if(getStandardAnalysis && getStandardAnalysis !== undefined && getStandardAnalysis !== null){
							if(getStandardAnalysis){
								this.setState({StandardAnalysis:getStandardAnalysis, standarClassId:getGradeOnly[0].value})
							}else {
								this.setState({StandardAnalysis:null, standarClassId:getGradeOnly[0].value})
							}
						}
					});
				}
			}
			
		});
	}
	academicSubjectAnalysis(getYear?:any){
		const { academicYear } = this.state;	
		if(getYear && getYear !== undefined && getYear !== null){
			this.getAcademicSubjectAnalysis(getYear)
		}else{
			this.getAcademicSubjectAnalysis(academicYear)
		}	
	}

	getAcademicSubjectAnalysis(academicYear?:any){
		const { getSchoolId } = this.props;
		let loadMoreType:any;
		if(academicYear){
			loadMoreType = {
				academic_year: academicYear,
				school_id:getSchoolId
			  }
			  this.props.fetchGetAllClassList(loadMoreType).then((res: any) => {	
				  const { getAllClassList }	= this.props;		 
			  if(getAllClassList && getAllClassList !== undefined && getAllClassList !== null){
				  if(getAllClassList.length > 0 && getSchoolId){
					  const getPerformanace:any = {
						  academic_year: academicYear,
						  class_id:  getAllClassList[0].id,
						  school_id:getSchoolId
						}
						this.setState({class_id:getAllClassList[0].id})
					  this.props.fetchSubjectAnalysis(getPerformanace).then((res:any)=>{
						const { getSubjectAnalysis } = this.props;
						if(getSubjectAnalysis && getSubjectAnalysis !== undefined && getSubjectAnalysis !== null){
							if(getSubjectAnalysis.length > 0){
								this.setState({SubjectAnalysis:getSubjectAnalysis, class_id: getAllClassList[0].id})
							}else {
								this.setState({SubjectAnalysis:[], class_id: getAllClassList[0].id})
							}
						}
					});
				  }else {
					this.setState({SubjectAnalysis:[]})
				} 		
			  }
		   })
		}
	}
	academicStandardAnalysis(getYear?:any){
	const { academicYear } = this.state;
	if(getYear && getYear !== undefined && getYear !== null){
		this.getAcademicStandardAnalysis(getYear)
	}else{
		this.getAcademicStandardAnalysis(academicYear)
	}
	}
	getAcademicStandardAnalysis(academicYear?:any){
		const { getSchoolId } = this.props;
		const postValue:any ={
			school_id:getSchoolId
		}
		this.props.fetchMyClassListGet(postValue).then((res:any)=>{
			const {getGradeOnly} = this.props;
			if(getGradeOnly && getSchoolId){
				if(getGradeOnly.length){
				const PostStandardAnalysis:any = {
					academic_year: academicYear,
					grade:getGradeOnly[0].value,
					school_id:getSchoolId
				  }
				  if(PostStandardAnalysis){
					this.props.fetchStandardAnalysis(PostStandardAnalysis).then((res:any)=>{
						const { getStandardAnalysis } = this.props;
						if(getStandardAnalysis && getStandardAnalysis !== undefined && getStandardAnalysis !== null){
							if(getStandardAnalysis){
								this.setState({StandardAnalysis:getStandardAnalysis, standarClassId:getGradeOnly[0].value})
							}else {
								this.setState({StandardAnalysis:null, standarClassId:getGradeOnly[0].value})
							}
						}
					});
				  }
				 
				}
			}
		});
	}
	getSubjectAnalysis = (event:any) =>{
		const { academicYear } = this.state;
		const { value } = event.target;
		const { getSchoolId } = this.props;
		if(academicYear){
			if(value){
				if(getSchoolId){
					const getPerformanace:any = {
						academic_year: academicYear,
						class_id:  value,
						school_id:getSchoolId
					  }
					  this.setState({class_id:value})
					this.props.fetchSubjectAnalysis(getPerformanace).then((res:any)=>{
						const { getSubjectAnalysis } = this.props;
						if(getSubjectAnalysis && getSubjectAnalysis !== undefined && getSubjectAnalysis !== null){
							if(getSubjectAnalysis.length > 0){
								this.setState({SubjectAnalysis:getSubjectAnalysis, class_id: value})
							}else {
								this.setState({SubjectAnalysis:[], class_id: value})
							}
						}
					});
				} else {
					const getPerformanace:any = {
						academic_year: academicYear,
						class_id:  value
					  }
					  this.setState({class_id:value})
					this.props.fetchSubjectAnalysis(getPerformanace).then((res:any)=>{
						const { getSubjectAnalysis } = this.props;
						if(getSubjectAnalysis && getSubjectAnalysis !== undefined && getSubjectAnalysis !== null){
							if(getSubjectAnalysis.length > 0){
								this.setState({SubjectAnalysis:getSubjectAnalysis, class_id: value})
							}else {
								this.setState({SubjectAnalysis:[], class_id: value})
							}
						}
					});
				}
				
			}
		}
		
	}
	getStandardAnalysis = (event:any) =>{
		const { academicYear } = this.state;
		const { value } = event.target;
		const { getSchoolId, getGradeOnly } = this.props;
		
		if(academicYear){
			const getGradeName:any = getGradeOnly.find((items:any)=>items.id === parseInt(value))
			if(value){
				if(getSchoolId){
					const getPerformanace:any = {
						academic_year: academicYear,
						school_id: getSchoolId,
						grade:getGradeName.value
					  }
					this.props.fetchStandardAnalysis(getPerformanace).then((res:any)=>{
						const { getStandardAnalysis } = this.props;
						if(getStandardAnalysis && getStandardAnalysis !== undefined && getStandardAnalysis !== null){
							if(getStandardAnalysis){
								this.setState({StandardAnalysis:getStandardAnalysis, standarClassId:value})
							}else {
								this.setState({StandardAnalysis:null, standarClassId:value})
							}
						}
					});
				} else {
					const getPerformanace:any = {
						academic_year: academicYear,
						grade:getGradeName.value
					  }
					this.props.fetchStandardAnalysis(getPerformanace).then((res:any)=>{
						const { getStandardAnalysis } = this.props;
						if(getStandardAnalysis && getStandardAnalysis !== undefined && getStandardAnalysis !== null){
							if(getStandardAnalysis){
								this.setState({StandardAnalysis:getStandardAnalysis, standarClassId:value})
							}else {
								this.setState({StandardAnalysis:null, standarClassId:value})
							}
						}
					});
				}			
			}
		}
	
	}
	cardSubjectAnalysisDownUp =  () =>{
const {addClassName} = this.state;
if(addClassName === 'height300') {
	this.setState({addClassName:''})
}else {
	this.setState({addClassName:'height300'})
}
	}
	cardStandardAnalysisDownUp =  () =>{
		const {addSAClassName} = this.state;
		if(addSAClassName === 'height300') {
			this.setState({addSAClassName:''})
		}else {
			this.setState({addSAClassName:'height300'})
		}
			}
			refreshSubjectAnalysis = () =>{
				const { loginProfile } = this.props;
				const {academicYear} = this.state
				if(loginProfile){
				if(loginProfile.usertype === 1){
					this.academicSubjectAnalysis(academicYear);
				} else if(loginProfile.usertype === 2 || loginProfile.usertype === 3) {
					this.commonSubjectAnalysis(academicYear)
				}	
			}
			}
			refreshStandardAnalysis = () =>{
				const { loginProfile } = this.props;
				const {academicYear} = this.state
				if(loginProfile){
				if(loginProfile.usertype === 1){
					this.academicStandardAnalysis(academicYear)
				} else if(loginProfile.usertype === 2 || loginProfile.usertype === 3) {
					this.commonStandardAnalysis(academicYear)
				}	
			}
			}
	render() {
		const { getAllClassList, getGradeOnly,isSubjectLoader,subjectAnalysisLoader,standardSubjectLoader,standardAnalysisLoader } = this.props;
		const { StandardAnalysis, SubjectAnalysis, addClassName, addSAClassName, standarClassId, class_id } = this.state;
		const issubjectAnalysisLoaderVisible = { display: isSubjectLoader ? "block" : subjectAnalysisLoader ? "block" :"none" };
		const isstandardAnalysisLoaderVisible = { display: standardSubjectLoader ? "block" : standardAnalysisLoader ? "block" :"none" };
		let labelsName:any;
		let backgroundColor:any;
		let dataCount:any;
		if(SubjectAnalysis !== undefined && SubjectAnalysis !== null){
			if(SubjectAnalysis && SubjectAnalysis.length > 0){
				labelsName = [];
				backgroundColor = [];
				dataCount = [];
				SubjectAnalysis.forEach((items:any)=>{
					labelsName.push(items.subject_name);
					backgroundColor.push(items.color_code)
					dataCount.push(items.performance)
				})
			}
		}
		
		if(StandardAnalysis !== undefined && StandardAnalysis !== null){
			if(StandardAnalysis){
				if(StandardAnalysis.graph_data){
					StandardAnalysis.graph_data.forEach((items:any)=>{
						items.backgroundColor = items.color_code;
						items.label = items.class_name;
						items.data = items.performance;
					})
				}
		}
		}
		return (
			<div>
				<div className="row">
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
						<div className={`card card-topline-red ${addClassName}`}>
						<div className="overlay" style={issubjectAnalysisLoaderVisible}><div className='custom-spinner-individual'></div></div>
							<div className="card-head">
								<header>Subject Analysis</header>
								<div className="tools">
									<div className="d-flex">
										<div>
										{getAllClassList ?
										<select name="className" id="" className="form-control" value={class_id}  onChange={this.getSubjectAnalysis}>
											{getAllClassList.map((items:any)=>(
												<option value={items.id}> {items.grade_standard} </option>
											))}
										</select>
											:null}	
										</div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh" onClick={this.refreshSubjectAnalysis}></span>
											<span className="t-collapse btn-color fa fa-chevron-down" onClick={this.cardSubjectAnalysisDownUp}></span>
										</div>
									</div>	
								</div>
							</div>
							<div className="card-body no-padding height-9" >
								{SubjectAnalysis && SubjectAnalysis !== undefined && SubjectAnalysis !== null && SubjectAnalysis.length > 0?
								<div className="row" >
									<Bar
										data={{
											labels: labelsName,
											datasets: [
												{
													backgroundColor: backgroundColor,
													borderColor: backgroundColor,
													borderWidth: 2,
													data: dataCount
												}
											]
										}}
										height={150}
										options={
											{											
											legend:{
												display:false
										 },
										
											title: {
												display: true,
												text: ''
											},
											scales : {
												yAxes: [{
													ticks: {
													  beginAtZero: true,
													  steps: 10,
													  stepValue: 5,
													  userCallback: function(label:any, index:any, labels:any) {
														  if (Math.floor(label) === label) {
															  return label;
														  }
													  }
													},
													gridLines : {
														color: 'rgba(170,170,170,1)',
              											lineWidth: 0.2
													},
													scaleLabel: {
														display: true,
														labelString: 'Performance (%)'
													  }
												  }],
												xAxes : [ {
      												barPercentage: 0.1,
													gridLines : {
														display : false
													},
													scaleLabel: {
														display: true,
														labelString: 'Subjects'
													  }
												} ]
											}
										  }}
									/>
								</div>
								:<CommonLoader/>}
							</div>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" >
						<div className={`card card-topline-red ${addSAClassName}`}>
						<div className="overlay" style={isstandardAnalysisLoaderVisible}><div className='custom-spinner-individual'></div></div>
							<div className="card-head">
								<header>Standard Analysis</header>
								<div className="tools">
								<div className="d-flex">
										<div>
										{getGradeOnly ?
										<select name="className" id="" value={standarClassId} className="form-control" onChange={this.getStandardAnalysis}>
											{getGradeOnly.map((items:any)=>(
												<option value={items.id}> {items.value} </option>
											))}
										</select>
										:null}	
										</div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh" onClick={this.refreshStandardAnalysis}></span>
											<span className="t-collapse btn-color fa fa-chevron-down" onClick={this.cardStandardAnalysisDownUp}></span>
										</div>
									</div>
									
								</div>
							</div>
							<div className="card-body no-padding height-9">
								{StandardAnalysis && StandardAnalysis !== undefined && StandardAnalysis !== null && StandardAnalysis.subject_name_list.length > 0?
								<div className="row">
									<Bar
										data={{
											labels: StandardAnalysis.subject_name_list,
											datasets: StandardAnalysis.graph_data
										}}
										height={150}
										options={{
											legend:{
												display:false
										 },
											title: {
												display: true,
												text: ''
											},
											scales : {
												yAxes: [{
													ticks: {
													  beginAtZero: true,
													  steps: 10,
													  stepValue: 5,
													  userCallback: function(label:any, index:any, labels:any) {
														  if (Math.floor(label) === label) {
															  return label;
														  }
													  }
													},
													gridLines : {
														color: 'rgba(170,170,170,1)',
              											lineWidth: 0.2
													},
													scaleLabel: {
														display: true,
														labelString: 'Performance (%)'
													  }
												  }],
												xAxes : [ {
      												barPercentage: 0.1,
													gridLines : {
														display : false
													},
													scaleLabel: {
														display: true,
														labelString: 'Classes'
													  }
												} ]
											}
										  }}
									/>
									</div>
								:<CommonLoader/>}
							</div>
						</div>
					</div>
				</div>
				{/* <div style={loadingDiary}><SpinnerLoader /></div> */}
			</div>
		);
	}
}
const mapStateToProps = (state:any) => {
	return {
		getChangeYearData:state.profile.getYear,
		loadingProfile:state.profile.dashBoardSchoolChartYearChange,
		loading: state.dashboard.loading,
		getAllClassList:state.diary.gradelist,
		loginProfile:state.profile.profileData,
		getSubjectAnalysis:state.dashboard.SubjectAnalysis,
		getStandardAnalysis:state.dashboard.StandardAnalysis,
		getGradeOnly:state.classes.my_class,
		subjectAnalysisLoader:state.dashboard.subjectAnalysisLoader,
		isSubjectLoader:state.diary.isSubjectLoader,
		standardSubjectLoader:state.classes.standardSubjectLoader,
		standardAnalysisLoader:state.dashboard.standardAnalysisLoader
	}
}
export default connect(mapStateToProps, {fetchSubjectAnalysis, fetchStandardAnalysis, 
	fetchGetAllClassList, stopYearDataDashBoardSchoolChart, fetchMyClassListGet, fetchProfilePost})(Schoolstatuschart);
	
