import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import { getAllSchoolNameList, getInstitutionDetailsA, setInstitutionDetailsA } from '../../store/usermanage/Actions';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import history from '../../History';
import { RouteComponentProps } from 'react-router';

interface InstitutionDetails {
    id: number;
    school_name: string;
    schoolPath: string;
    question: any;
}

export interface PostsOrganizationsProps extends RouteComponentProps<any> {
    getAllSchoolNameList: () => any;
    loading: boolean;
    loginProfile: any;
    getSchoolList: any;
    setInstitutionDetailsA: (data: any) => void;
    institutionDetails:InstitutionDetails
}

interface PropsOrganizationTypes {
    message: string;
    institutionId: string;
}

export class Organizations extends Component<PostsOrganizationsProps, PropsOrganizationTypes> {
    _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            message: '',
            institutionId: '',
        };
    }

    componentDidMount(): void {
       
        this._isMounted = true;       
        if(this.props.institutionDetails?.id !== null && this.props.institutionDetails?.id !== undefined  ){
            
            history.push({
                pathname: `/institutions/${this.props.institutionDetails?.id}`
            });
        }else{
            this.props.getAllSchoolNameList().then((res: any) => {
                if (this._isMounted) {
                    window.scrollTo(0, 0);
                }
            });
        }
       
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    getSchoolList() {
        const { getSchoolList } = this.props;
        if (getSchoolList && Array.isArray(getSchoolList) && getSchoolList.length > 0) {
            return getSchoolList.map((items: any) => {
                items['schoolPath'] = '/institutions/';
                items['question'] = [
                    {
                        id: 1,
                        name: 'Classes',
                        icon: 'fa fa-file-text-o',
                        URL: '/classes/'
                    },
                    {
                        id: 2,
                        name: 'Instant Feedback',
                        icon: 'fa fa-commenting-o',
                        URL: '/instant_feedback/'
                    },
                    {
                        id: 3,
                        name: 'Quizzes',
                        icon: 'fa fa-file-text',
                        URL: '/quizzes/'
                    }
                ];
                return items;
            });
        }
        return [];
    }

    getcategoriesBaseOpenPage = (path: any, SchoolId: any) => {
        if (path && SchoolId) {
            history.push({
                pathname: path
            });
        }
    };

    setInstitutionsPage = (pageId: any) => {
        history.push({
            pathname: `/institutions/${pageId?.id}`,
            state: { institutionId: pageId?.id }
        });
        if(pageId){
            this.props.setInstitutionDetailsA(pageId);
        }
        if (this._isMounted) {
            this.setState({ institutionId: pageId?.id });
        }
    };

    render() {
        const { loading } = this.props;
        const loadingTextCSS = { display: loading ? 'block' : 'none' };
        const schoolList = this.getSchoolList();

        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <BreadCrumb
                            titleName={['Institutions']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Institutions']}
                        />
                        {schoolList && schoolList.length > 0 ? (
                            <div className="row">
                                {schoolList.map((items: any) => (
                                    <div className="col-md-3" key={items.id}>
                                        <div className="card card-topline-red">
                                            <div className="m-3 mt-0 mb-0">
                                                <div>
                                                    <div className="width100 text-center">
                                                        <button
                                                            className="mdl-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-pink"
                                                            onClick={() => this.setInstitutionsPage(items)}
                                                        >
                                                            <div>{items.school_name}</div>
                                                        </button>
                                                    </div>
                                                    <div className="classes-height ml-3">
                                                        <ul className="text-left">
                                                            {items.question.map((questionItem: any) => (
                                                                <li className="mb-3" key={questionItem.id}>
                                                                    <Link to={`${questionItem.URL}${items.id}`}>
                                                                        <span>
                                                                            <i className={questionItem.icon} aria-hidden="true"></i>
                                                                        </span>{' '}
                                                                        {questionItem.name}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <CommonLoader />
                        )}
                    </div>
                </div>
                <div style={loadingTextCSS}>
                    <SpinnerLoader />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllSchoolNameList: () => dispatch(getAllSchoolNameList()),
        setInstitutionDetailsA: (data: any) => dispatch(setInstitutionDetailsA(data)),
        getInstitutionDetailsA: () => dispatch(getInstitutionDetailsA()),
    };
};

const mapStateToProps = (state: any) => {
    return {
        loginProfile: state.profile.profileData,
        getSchoolList: state.userManage.category,
        loading: state.userManage.loading,
        institutionDetails: state.userManage.institutionDetails
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
