import React, { Component } from 'react'
import { connect } from 'react-redux'
import Schoolstatuschart from './SchoolStatusChart';
import Teacherupdatedetails from './TeacherDetails';
import Totalcount from './TotalCount';
import { RouteComponentProps } from 'react-router';
import { getAllSchoolNameList, getInstitutionDetailsA, setInstitutionDetailsA } from '../../store/usermanage/Actions';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import BreadCrumb from '../../components/BreadCrumb';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import history from '../../History';
interface OwnSchoolDetailsProps extends RouteComponentProps<OwnPropsParams> {
    getChangeYearData?: any;
    getAllSchoolNameList: () => any;
    getInstitutionDetailsA: () => any;
    setInstitutionDetailsA: (data?: any) => any;
    getSchoolNameList: any;
    loading: any;
    institutionDetails: any
}
export interface PropsTypes {
    getCorrectSchoolId: any,
    currectYear?: any
}
export class ViewSchoolDetailsTeam extends Component<OwnSchoolDetailsProps, PropsTypes> {
    cookies: any = new Cookies();
    getClassPropsValue: any
    constructor(props: any) {
        super(props);
        this.state = {
            getCorrectSchoolId: this.props.match.params.id,
            currectYear: this.props.getChangeYearData
        }
        this.onCheckViewDetails = this.onCheckViewDetails.bind(this); 
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.props.getAllSchoolNameList();
        this.props.getInstitutionDetailsA();
        console.log(this.props.institutionDetails,'institutionDetails')
        this.getClassPropsValue = this.props.match.params.id;
        if (this.getClassPropsValue) {
            this.setState({ getCorrectSchoolId: this.getClassPropsValue })
        }
        
    }
    onCheckViewDetails(event: any) {        
        this.props.setInstitutionDetailsA([]);
        setTimeout(()=>{
            history.push({
                pathname: `/institutions`
            });
        },1000)       
    }
    getFilterSchoolName = (event: any) => {
        const { value } = event.target;
        if (value) {
            this.setState({ getCorrectSchoolId: value })
        }
    }
    render() {
        // const { institutionDetails } = this.props;
        const { loading, getSchoolNameList } = this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        const getSchoolId: any = this.props.match.params.id;

        this.cookies.set('ACADEMICSCHOOLID', this.props.match.params.id)
        let schoolName: any;
        if (getSchoolNameList && getSchoolId) {
            const getSchoolName: any = getSchoolNameList.find((item: any) => item.id === parseInt(getSchoolId))
            if (getSchoolName) {
                schoolName = getSchoolName.school_name
            }
        }
        this.getClassPropsValue = this.props.match.params.id;
        return (
            <div className="mt-3">
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <BreadCrumb
                            titleName={[schoolName]}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['School Dashboard']}
                            baseName={['Institutions']}
                            baseURL={['institutions']}
                            schoolStatus={this.onCheckViewDetails}
                        />
                        {this.getClassPropsValue ?
                            <>
                                <Totalcount getSchoolId={this.getClassPropsValue} />
                                <Schoolstatuschart getSchoolId={this.getClassPropsValue} />
                                <Teacherupdatedetails getSchoolId={this.getClassPropsValue} />
                            </>
                            : null}
                    </div>
                </div>
                {/* <div style={loadingTextCSS}><SpinnerLoader /></div> */}
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllSchoolNameList: () => dispatch(getAllSchoolNameList()),
        getInstitutionDetailsA: () => dispatch(getInstitutionDetailsA()),
        setInstitutionDetailsA: (data: any) => dispatch(setInstitutionDetailsA(data)),
    };
};
const mapStateToProps = (state: any) => {
    return {
        getChangeYearData: state.profile.getYear,
        loading: state.dashboard.loading,
        getSchoolNameList: state.userManage.category,
        institutionDetails: state.userManage.institutionDetails
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSchoolDetailsTeam);
