import React from 'react';
import { Link } from 'react-router-dom';
import { UserRoles, academicMenuList, schoolAdminMenuList, teacherMenuList, noticeBoard } from '../../services/Constants'
import { connect } from 'react-redux';
import { fetchProfilePost, getYearData,
	 DiaryNotificationMessage, NoticeBoardMessage, 
	 TokenValidationPost, YearListGet, DurationListGet, ResetProfile } from '../../store/profile/Actions'
import { RootState } from '../../store/Index'
import { LogoutPost } from '../../store/authentication/Actions'
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { GetCorrectSubject } from '../../store/subject/Actions';
import { CommonModel } from '../../components/CommonModel';
import { PropTopType } from './Types';
import history from '../../History';
import {ResetDashboard} from '../../store/dashboard/Actions';
import {resetClasses} from '../../store/classes/Actions';
import {ResetDiary} from '../../store/diary/Actions';
import {ResetQuestion} from '../../store/question/Actions';
import {ResetSchool} from '../../store/school/Actions';
import {ResetStudent} from '../../store/student/Actions';
import {ResetSubject} from '../../store/subject/Actions';
import {ResetTeacher} from '../../store/teacher/Actions';
import {ResetUserManage} from '../../store/usermanage/Actions';

export interface ProfileDetails {
	getProfileDetails?:any;
	getChangeYearData?:any;
	YearList: any;
	getYearData:(postvalue?:any) => any;
	fetchProfilePost: () => any;
	LogoutPost: () => any;
	ResetDashboard:()=>any;
	resetClasses: () => any;
	ResetDiary: () => any;
	ResetProfile: () => any;
	ResetQuestion: () => any;
	ResetSchool: () => any;
	ResetStudent: () => any;
	ResetSubject: () => any;
	ResetTeacher: () => any;
	ResetUserManage:()=> any;
	TokenValidationPost: () => any;
	YearListGet: () => any;
	DurationListGet:() => any;
	GetCorrectSubject: (postValue:any) => void; 
	DiaryNotificationMessage:(postValue:any) => any;
	NoticeBoardMessage:(postValue:any) => any;
	loading: boolean;
	currentAcademicYear:any;
}

class Topheader extends React.Component<ProfileDetails, PropTopType> {
	showMenuOption:any = 'arrow';
	constructor(props: any) {
		super(props);
		this.state = {
			acadamicAdmin: false,
			schoolAdmin: false,
			bothData: false,
			teacher: false,
			parent: false,
			showLogout: false,
			getYear: this.props.getChangeYearData,
			sideMenuBar:false,
			menuListclose:'fa fa-angle-right',
			menuListOpen:false,
			getProfile:this.props.getProfileDetails,
			userFirstName:'',
			userLastName:'',
			schoolName:'',
			userRole:'',
			getMenuList:[],
			logOutDetails:[],
			sideSubMenuBar:false,
			isAcadamicAdmin:false
		};
	  }

	public closeLogout = () => {
		this.setState({ showLogout: false });
	}

	public ShowLogoutBox = () => {
		this.setState({ showLogout: true });
		let postValue:any = {
			type:'logout',
			title:'Logout',
			action_status:'Are you sure want to Logout?'
		}
		if(postValue){
			this.setState({logOutDetails:postValue})
		}
	}
	componentDidMount(): void {
		const { getYear } = this.state;
		this.getMenuList()
		this.props.TokenValidationPost();
		this.getCurrectYear();
		this.props.YearListGet().then((res:any)=>{
			
		});
		this.props.DurationListGet();
		const { getProfileDetails } = this.props;
		if(getYear){
			if(getProfileDetails.school_id){
				const postValue = {
					academic_year:getYear,
					school_id:getProfileDetails.school_id
				}
				this.props.GetCorrectSubject(postValue);
			}
		}
	}

	getCurrectYear(){
		const { getYear } = this.state;
		if(getYear && getYear !== undefined && getYear !== null){
			this.setState({getYear:getYear})
		}else {
			let dateYear = this.props.currentAcademicYear && this.props.currentAcademicYear.data && this.props.currentAcademicYear.data.current_academic_year ?
							this.props.currentAcademicYear.data.current_academic_year : '';
			this.props.getYearData(dateYear).then((res:any)=>{
				const {getChangeYearData,currentAcademicYear} = this.props;
				if(currentAcademicYear && currentAcademicYear.data && currentAcademicYear.data.current_academic_year){
					this.setState({getYear:currentAcademicYear.data.current_academic_year})
				}else{
					let getYearData:any = this.props.YearList.slice(-1).pop()
					if(getYearData){
						if(getYearData.value === getChangeYearData){
							this.setState({getYear:getYearData.value})
						}
					}else{
							this.setState({getYear:getChangeYearData})
						}
				}
				
			})
		}
	}

	getMenuList(){
		this.props.fetchProfilePost().then((res:any)=>{
			const { getProfileDetails } = this.props;
		// This is the function used to user type based show menu
		if(getProfileDetails && getProfileDetails !== undefined && getProfileDetails !== null){
			if (getProfileDetails.usertype === parseInt(UserRoles.acadamicAdmin)) {
				this.setState({getMenuList:academicMenuList, 
					schoolName:getProfileDetails.school_name, 
					userFirstName:getProfileDetails.firstname, 
					userLastName:getProfileDetails.lastname, 
					userRole:getProfileDetails.role,
					isAcadamicAdmin:true
				})
			} else if (getProfileDetails.usertype === parseInt(UserRoles.schoolAdmin)) {
				this.setState({getMenuList:schoolAdminMenuList, 
					schoolName:getProfileDetails.school_name, 
					userFirstName:getProfileDetails.firstname, 
					userLastName:getProfileDetails.lastname, 
					userRole:getProfileDetails.role})
			} else if (getProfileDetails.usertype === parseInt(UserRoles.teacher)) {
				this.setState({getMenuList:teacherMenuList, 
					schoolName:getProfileDetails.school_name, 
					userFirstName:getProfileDetails.firstname, 
					userLastName:getProfileDetails.lastname, 
					userRole:getProfileDetails.role})
			}
		}
		});
}
	ChangeYear = (e:any) =>{
		const getChangeYear:any = e.target.value;
		if(getChangeYear){
			this.props.getYearData(getChangeYear).then((res:any)=>{
				this.setState({getYear:getChangeYear})
			});
		}
	}
		
	hideSubmenuList = (getValue?:any, index?:any) =>{
		const { sideMenuBar, getMenuList } = this.state;
		if(!!getValue.url === true && getValue.url === '#'){
			if(getMenuList.length > 0){
				getMenuList.forEach((menuitem:any, i:any)=>{
					if(i === index){
						const menuListActive: any = document.getElementById(index);
						if (menuListActive.className === 'pl-3 sub-menu') {
							menuListActive.classList.remove('sub-menu');
							this.setState({menuListclose:'fa fa-angle-down'})
						}else{
							menuListActive.classList.add('sub-menu');
							this.setState({menuListclose:'fa fa-angle-right'})
						}
				
					}
				})
			}
		}else if(!!getValue.url === true) {
			if(sideMenuBar === true){
				this.setState({ sideMenuBar: false })
			} else {
				this.setState({ sideMenuBar: true })
			}
				history.push(getValue.url)
				this.setState({sideSubMenuBar:false})
		}
	}
	
	public hideToggel = () =>{
		const { sideMenuBar } = this.state;
		if(sideMenuBar === true){
			this.setState({ sideMenuBar: false })
		} else {
			this.setState({ sideMenuBar: true })
		}
	}
	
onMouseEnterHandler = (getValue:any) => {
	if( getValue ==='Manage' ){
		this.setState({menuListclose:'arrow open'})
	}	
    }
onMouseLeaveHandler = (getValue:any) =>{
	if( getValue ==='Manage' ){
		this.setState({menuListclose:'arrow'})
	}
    }
	getConfirmationLogout = () =>{
		this.props.LogoutPost().then((res:any)=>{
			this.props.getYearData()
			this.clearAllData()
		})
	}
	// This is function used to clear all data
	clearAllData = () =>{
		this.props.ResetDashboard()
		this.props.resetClasses()
		this.props.ResetDiary()
		this.props.ResetProfile()
		this.props.ResetQuestion()
		this.props.ResetSchool()
		this.props.ResetStudent()
		this.props.ResetSubject()
		this.props.ResetTeacher()
		this.props.ResetUserManage()
	}
	render() {
		const { getYear, getMenuList, schoolName, userFirstName, userLastName, userRole, showLogout, logOutDetails,isAcadamicAdmin } = this.state;
		const {loading, getProfileDetails} = this.props;
		const loadingTopNav = { display: loading ? "block" : "none" };
		const noticeData:any = noticeBoard;	
		return (
			<div>
				<CommonModel
       onState={showLogout}
       currentState={logOutDetails}
       onConfirm={this.getConfirmationLogout}
       onCancel={this.closeLogout}
       />
				{/* start header */}
				{this.props.YearList && getProfileDetails && getProfileDetails !== undefined && getProfileDetails !== null && getMenuList?
				<>
					<div className="page-header navbar navbar-fixed-top">
						<div className="page-header-inner ">
							{/* logo start */}
							<div className="page-logo pl-4">
								<Link to="#" className="clickMenuList">
									<i className="fa fa-graduation-cap mr-2" aria-hidden="true"></i>
									<span className="logo-default">DAPS</span> </Link>
							</div>
							{/* logo end */}
							{/* start mobile menu */}
							<div className="menu-toggler responsive-toggler" onClick={() => this.hideToggel()}>
								<span></span>
							</div>
							<div>
						
							</div>
							{/* end mobile menu */}
							{/* start header menu */}
							<div className="top-menu">
							
								<ul className="nav navbar-nav pull-right">
									<li className="schoolnametop">
									{schoolName && schoolName !== undefined && schoolName !== null?
							<span className="username username-hide-on-mobile mt-1 mr-2 userNameTitle"> {schoolName}</span>
							:null}
									</li>
									{/* start manage user dropdown */}
									<li className="dropdown dropdown-user">
									<select name="year" className="form-control mt-2" value={getYear} onChange={this.ChangeYear}>
										{
												this.props.YearList.map((menuItems: any) => (
													<option value={menuItems.value}>
														{menuItems.display_value}
													</option>
												))
											}
										</select>
									</li>
										
									<li className="dropdown dropdown-user mr-3">
										<Link to={'#'} className="dropdown-toggle pr-2 pb-0 pt-0" data-toggle="dropdown" data-hover="dropdown"
											data-close-others="true">
											<img alt="" className="img-circle " src="assets/img/profile_small.jpg" />
											<span className="username username-hide-on-mobile mt-1 mr-2 userNameTitle"> {userFirstName} {userLastName}</span>
											<p className="d-block usernamesubtitle">{userRole} <i className="fa fa-angle-down"></i></p>
										</Link>
										<ul className="dropdown-menu dropdown-menu-default animated jello">
										<li className={isAcadamicAdmin ? 'show' : 'hidden'}>
												<Link to={{pathname:'/change_password',state:getProfileDetails}}>
													<i className="icon-logout"></i>
													 Change Password</Link>
											</li>
											<li>
												<Link to={'#'} onClick={() => this.ShowLogoutBox()}>
													<i className="icon-logout"></i> Log Out </Link>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
						<div className="navbar-custom">
							<div className="hor-menu hidden-sm hidden-xs">
								<ul className="nav navbar-nav">
									{getMenuList?
									getMenuList.map((items:any, i:any)=>(
										<li className="mega-menu-dropdown" key={i}>
										<Link to={items.url} className="dropdown-toggle">
											<i className={items.icon} aria-hidden="true"></i> {items.menu_name}
											{
												items.submenu?
												<>
												<i className="fa fa-angle-down"></i>
												<span className="selected"></span>
												<span className="arrow open"></span>
												</>
												:null
											}
										</Link>
										{
											items.submenu?
											<ul className="dropdown-menu">
												<li>
													<div className="mega-menu-content">
														<div className="row">
															<div className="col-md-12">
																<ul className="mega-menu-submenu">
																{items.submenu.map((submenu:any, index:any)=>(
																	<li className="nav-item" key={index}>
																	<Link to={submenu.url} className="nav-link ">
																		<span className="title">{submenu.menu_name}</span>
																	</Link>
																</li>
																))}
																</ul>
															</div>
														</div>
													</div>
												</li>
											</ul>
											
											:null
										}
									</li>
									
									))
									:null}
								</ul>
							</div>
						</div>
					</div>
					{/* mobile menu */}
					<div className="page-container">
					<div className="sidebar-container" style={{ display: this.state.sideMenuBar ? "block" : "none" }}>
					<div className="sidemenu-container fixed-menu">
						<div id="remove-scroll">
							<ul className="sidemenu page-header-fixed p-t-20" data-keep-expanded="false" data-auto-scroll="true"
								data-slide-speed="200">
							{getMenuList?
							getMenuList.map((items:any, i:any)=>(
								<li className="nav-item companymenuli" key={i} onClick={() => this.hideSubmenuList(items, i)}>
								<div className="nav-link nav-toggle menuNameList pointer">
									<i className={items.icon} aria-hidden="true"></i> 
									<span className="title">{items.menu_name}</span>
									{items.submenu ? 
									<>
									<span className="pull-right"><i className={this.state.menuListclose} aria-hidden="true"></i></span>
									</>
									:null}
								</div>
								{
									items.submenu?
									<ul className="pl-3 sub-menu" id={i}>
											{items.submenu.map((submenu:any, index:any)=>(
												<li className="nav-item" key={index}>
											<Link to={submenu.url} className="nav-link clickMenuList subMenuList" id={index} onClick={() => this.hideSubmenuList(submenu, index)}>
												<span className="title"> {submenu.menu_name}</span>
											</Link>
							
										</li>
											))}
									</ul>
									
									:null
								}
							</li>
							))
							:null}
							</ul>
						</div>
					</div>
				</div>
				</div>
				</>
					: <div style={loadingTopNav}><SpinnerLoader /></div>}
				{/* end header */}
				{/* <!-- start chat sidebar --> */}
				<>
			<div className="chat-sidebar-container" data-close-on-body-click="false">
				<div className="chat-sidebar">
					<ul className="nav nav-tabs">
						<li className="nav-item">
							<a href="#quick_sidebar_tab_1" className="nav-link active tab-icon" data-toggle="tab">Notice Board
							</a>
						</li>
						<li className="nav-item">
							<a href="#quick_sidebar_tab_3" className="nav-link tab-icon" data-toggle="tab"> Diary
							</a>
						</li>
					</ul>
					<div className="tab-content">					
						{/* <!-- Start Doctor Chat --> */}
						<div className="tab-pane chat-sidebar-chat animated in show active slideInRight" id="quick_sidebar_tab_1">
							<div className="chat-sidebar-list">
								<div className="chat-sidebar-chat-users slimscroll-style" data-rail-color="#ddd"
									data-wrapper-class="chat-sidebar-list">
									<div className="chat-header">
										<h5 className="list-heading"> <i className="fa fa-comments-o"></i> Latest Notice Board</h5>
									</div>
									<ul className="media-list list-items">
										{noticeData?
										noticeData.map((items:any, i?:any)=>(
										<li className="media" key={i}>
											<div className="media-body">
												<p >{items.message_title}</p>
												<div className="media-heading-sub">{items.update_time}</div>
											</div>
										</li>
										))
										:null}
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- End Doctor Chat --> */}
						{/* <!-- Start Setting Panel --> */}
						<div className="tab-pane chat-sidebar-chat animated slideInRight" id="quick_sidebar_tab_3">
							<div className="chat-sidebar-list">
								<div className="chat-sidebar-chat-users slimscroll-style" data-rail-color="#ddd"
									data-wrapper-class="chat-sidebar-list">
									<div className="chat-header">
										<h5 className="list-heading"> <i className="fa fa-comments-o"></i> Latest Diary</h5>
									</div>
									<ul className="media-list list-items">
									{noticeData?
										noticeData.map((items:any, index?:any)=>(
										<li className="media" key={index}>
											<div className="media-body">
												<p >{items.message_title}</p>
												<div className="media-heading-sub">{items.update_time}</div>
											</div>
										</li>
										))
										:null}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={loadingTopNav}><SpinnerLoader /></div>
			</>
			{/* <!-- end chat sidebar --> */}
			</div>
		);
	}
}
const mapStateToProps = ({ schools, login, profile }: RootState) => {
	return {
		loading: profile.loading,
		getChangeYearData:profile.getYear,
		getProfileDetails: profile.profileData,
		YearList: profile.YearList,
		currentAcademicYear: login.items
	};
};
export default connect(
	mapStateToProps, { fetchProfilePost, 
		LogoutPost, 
		TokenValidationPost, 
		YearListGet, 
		DurationListGet, 
		GetCorrectSubject, 
		getYearData, 
		DiaryNotificationMessage, 
		NoticeBoardMessage, ResetDashboard, resetClasses, ResetDiary, ResetProfile, ResetQuestion, ResetSchool, ResetStudent, ResetSubject, ResetTeacher, ResetUserManage }
)(Topheader);
