import React, { Component } from 'react';
import { connect } from 'react-redux'
import { fetchDashboard } from '../../store/dashboard/Actions';
import { fetchProfilePost, stopYearDataDashBoardCount } from '../../store/profile/Actions';

export type PostsSchoolProps = {
	getChangeYearData?:any;
	fetchDashboard: (postValue: any) => any;
	fetchProfilePost:() => any;
	stopYearDataDashBoardCount:()=> any;
	getSchoolCountList?: any,
	getSchoolId?:any;
	getCurrectYear?:any;
	dashboardCountLoader?:boolean;
}
interface StateTypes {
	dashboardCound:any,
	getYear?:null
}
class Totalcount extends Component<PostsSchoolProps, StateTypes> {
	public state = {
		getYear:null,
		dashboardCound:[]
    }
	componentDidMount(): void {
		window.scrollTo(0, 0);
		this.getDashboardCount()
	}

	getDashboardCount(getYearList?:any){
		if(getYearList && getYearList !== undefined && getYearList !== null){
			this.getDashboardData(getYearList)
			this.setState({getYear:getYearList})
		}else{
			this.getDashboardData(this.props.getChangeYearData)
			this.setState({getYear:this.props.getChangeYearData})
		}
	}

	getDashboardData(getYear?:any){
		let postValue:any;
		this.setState({dashboardCound:[]})
			if(this.props.getSchoolId){
				postValue = {
					academic_year: getYear,
					school_id: this.props.getSchoolId
				}
				this.props.fetchDashboard(postValue).then((res:any)=>{
					this.getTeacherCount()
				});
			}else {
				postValue = {
					academic_year: getYear
				}
				this.props.fetchDashboard(postValue).then((res:any)=>{
					this.getTeacherCount()
				});
			}
	}

	componentWillUpdate(nextProps:any, nextState:any) {
		const {getCurrectYear} = this.props;
		if(getCurrectYear === true){
			this.getDashboardCount(this.props.getChangeYearData)
		}
	}
	componentDidUpdate() {
		const {getCurrectYear} = this.props;
		if(getCurrectYear === true){
			this.props.stopYearDataDashBoardCount()
		}
	}
	getTeacherCount(){
		const { getSchoolCountList } = this.props;
		if(getSchoolCountList && getSchoolCountList !== undefined && getSchoolCountList !== null){
			getSchoolCountList.forEach((items:any)=> {
				if(items.title === 'Teachers'){
				const getTeachers:any = getSchoolCountList.find((item:any)=> item.title === 'Teachers')
					if(getTeachers.title === 'Teachers'){
						items['bg_class'] = "bg-blue";
						items['icon_class'] = "fa fa-users";
					}
				}else if(items.title === 'Students'){
					const getStudents:any = getSchoolCountList.find((item:any)=> item.title === 'Students')
					if(getStudents){
						items['bg_class'] = "bg-orange";
						items['icon_class'] = "fa fa-users";					}
					
				}else if(items.title === 'Quizzes'){
					const getQuizzes:any = getSchoolCountList.find((item:any)=> item.title === 'Quizzes')
					if(getQuizzes){
						items['bg_class'] = "bg-purple";
						items['icon_class'] = "fa fa-file-text";					}
					
				}else if(items.title === 'Instant Feedback') {
					const getInstantFeedback:any = getSchoolCountList.find((item:any)=> item.title === 'Instant Feedback')
					if(getInstantFeedback){
						items['bg_class'] = "bg-success";
						items['icon_class'] = "fa fa-file-text";
					}					
				}	
				
			})
			this.setState({dashboardCound:getSchoolCountList})
		} else {
			this.setState({dashboardCound:[]})
		}
	}
	
    render() {
		const { dashboardCound } = this.state;
		const {dashboardCountLoader} = this.props;
		const loaderDashboardCount = { display: dashboardCountLoader ? "block" : "block", borderRadius:"25px"  };
        return (
			<div>
				<div className="state-overview">
						<div className="row">
							{ dashboardCound && dashboardCound !== null && dashboardCound.length !==0 ?
								dashboardCound.map((items: any) => (
									<div className="col-xl-3 col-md-6 col-12" >
										<div className={`info-box p-0 pl-3 ${items.bg_class}`}>
											<span className="info-box-icon push-bottom">
												<i className={items.icon_class} aria-hidden="true"></i>
											</span>
											<div className="info-box-content">
												<span className="info-box-text">{items.title}</span>
												<span className="info-box-number">{items.count}</span>
												<div className="progress">
													<div className="progress-bar width-60"></div>
												</div>
												<span className="progress-description">
													Total {items.title}
												</span>
											</div>
											{/* info-box-content */}
										</div>
										{/* info-box */}
									</div>
								)) :
							<div className="col-12 mb-3" >
								<div className={`info-box`} style={{ marginBottom: 0 }}>
									<div className="overlay" style={loaderDashboardCount}><div className='custom-spinner-individual'></div></div>
								</div>
							</div>
							}
						</div>
				</div>
			</div>
        );
    }
}
const mapStateToProps = (state:any) => {
	return {
		getChangeYearData:state.profile.getYear,
		getCurrectYear:state.profile.dashBoardCountYearChange,
		getSchoolCountList:state.dashboard.DashboardCount,
		dashboardCountLoader:state.dashboard.dashboardCountLoader
	}
}
export default  connect(mapStateToProps, {fetchDashboard, fetchProfilePost, stopYearDataDashBoardCount})(Totalcount);
