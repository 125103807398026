import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import { fetchSubjectPerformance } from "../../store/dashboard/Actions";
import { GetTeacherCorrectSubject } from "../../store/subject/Actions";
import CommonLoader from "../../components/CommonLoader";
import { DurationListGet } from "../../store/profile/Actions";
import { option, propsTeacherType } from "./Types";
import { stopYearData } from '../../store/profile/Actions'

export type PostsTeacherProps = {
  getChangeYearData?:any;
	stopYearDate?:any;
  fetchSubjectPerformance: (postValue: any) => any;
  GetTeacherCorrectSubject: (postValue: any) => any;
  DurationListGet: () => any;
  stopYearData:()=>any;
  getProfile?: any;
  getSubjectPerform?: any;
  getDuration?: any;
  loading?: boolean;
  TeacherCorrectSubjectList?: any;
  isSubjectClassLoader?:boolean;
  isteacherSubjectPerdormanceLoader?:boolean
};

export class SubjectPerformanceViews extends Component<
  PostsTeacherProps,
  propsTeacherType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      schoolId: null,
      academicYear: null,
      classId: null,
      attendFilter: "This Year",
      subjectId: "",
      subjectperId: "",
      subjectFilterYear: "This Year",
      subject_name: [],
      performance: [],
      color_code: [],
      subject_name_Perform: [],
      performance_Perform: [],
      color_code_Perform: [],
      labelNameList: [],
      SubjectPerformance: [],
      addSAClassName: "height300",
      SubjectPerformanceRecord: null,
    };
  }

  componentDidMount(): void {
    this.UpdateYearDate()
  }
    
  componentWillUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.UpdateYearDate(this.props.getChangeYearData)
    }
}

componentDidUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.props.stopYearData()
    }
}

UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.getSubjectPerform(getYear)
        this.setState({academicYear:getYear})
    }else {
        this.getSubjectPerform(this.props.getChangeYearData)
        this.setState({academicYear:this.props.getChangeYearData})
    }
}
getSubjectPerform(getYear?:any){
  window.scrollTo(0, 0);
  // this.props.DurationListGet();
  this.getTeacherSubjectList(getYear);
}
  getTeacherSubjectList(getYear?:any) {
    const { getProfile } = this.props;
    if (getProfile && getProfile !== null && getProfile !== undefined) {
      let postValue: any = {
        teacher_id: getProfile.ldap_id,
        academic_year:getYear,
      };
      this.props.GetTeacherCorrectSubject(postValue).then((res: any) => {
        const { TeacherCorrectSubjectList } = this.props;
        if (
          TeacherCorrectSubjectList &&
          TeacherCorrectSubjectList !== undefined &&
          TeacherCorrectSubjectList !== null &&
          TeacherCorrectSubjectList.length > 0
        ) {
          this.setState({
            subjectperId: TeacherCorrectSubjectList[0].id,
            correctSubjectList: TeacherCorrectSubjectList,
          });
          let postValue:any = {
            teacher_subject:TeacherCorrectSubjectList,
            academic_year:getYear
          }
          this.getSubjectPerformance(postValue);
          this.setState({ subjectFilterYear: "This Year" });
        } else {
          this.setState({ correctSubjectList: [], SubjectPerformanceRecord: null });
        }
      });
    }
  }

  getSubjectPerformance(getValue?: any) {
    let subjectId: any = getValue;
    if (subjectId.academic_year) {
      if (getValue) {
        const getPerformanace: any = {
          academic_year:subjectId.academic_year,
          subject_id: subjectId.teacher_subject[0].id,
          filter: "This Year",
        };
        this.props.fetchSubjectPerformance(getPerformanace).then((res: any) => {
          const { getSubjectPerform } = this.props;
          if (
            getSubjectPerform &&
            getSubjectPerform !== undefined &&
            getSubjectPerform !== null &&
            getSubjectPerform.length > 0
          ) {
            this.setState({ SubjectPerformance: getSubjectPerform });
            this.barchartView(getSubjectPerform);
          } else {
            this.setState({ SubjectPerformanceRecord: null });
          }
        });
      }
    }
  }

  // this is the function use to filter subject class
  getSubjectFilterSubject = (e: any) => {
    const { subjectFilterYear, academicYear } = this.state;
    const { value } = e.target;
    const getPerformanace: any = {
      academic_year: academicYear,
      subject_id: value,
      filter: subjectFilterYear,
    };
    this.setState({ subjectperId: value });
    this.props.fetchSubjectPerformance(getPerformanace).then((res: any) => {
      const { getSubjectPerform } = this.props;
      if (
        getSubjectPerform &&
        getSubjectPerform !== undefined &&
        getSubjectPerform !== null &&
        getSubjectPerform.length > 0
      ) {
        this.setState({ SubjectPerformance: getSubjectPerform });
        this.barchartView(getSubjectPerform);
      } else {
        this.setState({ SubjectPerformanceRecord: null });
      }
    });
  };

  getYearSubjectChange = (e: any) => {
    const { subjectperId, academicYear } = this.state;
    const { value } = e.target;
    const getPerformanace: any = {
      academic_year:academicYear,
      subject_id: subjectperId,
      filter: value,
    };
    this.setState({ subjectFilterYear: value });
    this.props.fetchSubjectPerformance(getPerformanace).then((res: any) => {
      const { getSubjectPerform } = this.props;
      if (
        getSubjectPerform &&
        getSubjectPerform !== undefined &&
        getSubjectPerform !== null &&
        getSubjectPerform.length > 0
      ) {
        this.setState({ SubjectPerformance: getSubjectPerform });
        this.barchartView(getSubjectPerform);
      } else {
        this.setState({ SubjectPerformanceRecord: null });
      }
    });
  };
  cardSubjectPerformanceDownUp = () => {
    const { addSAClassName } = this.state;
    if (addSAClassName === "height300") {
      this.setState({ addSAClassName: "" });
    } else {
      this.setState({ addSAClassName: "height300" });
    }
  };
  refreshSubjectPerformance = () => {
    const {academicYear} = this.state;
    this.getTeacherSubjectList(academicYear);
  };
  barchartView(SubjectPerformance: any) {
    let subject_name_Perform: any = [];
    let performance_Perform: any = [];
    let color_code_Perform: any = [];
    let labelNameList: any = [];

    if (
      SubjectPerformance &&
      SubjectPerformance !== null &&
      SubjectPerformance !== undefined
    ) {
      subject_name_Perform = [];
      performance_Perform = [];
      color_code_Perform = [];
      labelNameList = [];
      SubjectPerformance.forEach((items: any) => {
        performance_Perform.push(items.performance);
        color_code_Perform.push(items.color_code);
        subject_name_Perform.push(items.subject_name);
        labelNameList.push(items.class_name);
      });
      let postValue: any = {
        performance_Perform: performance_Perform,
        color_code_Perform: color_code_Perform,
        subject_name_Perform: subject_name_Perform,
        labelNameList: labelNameList,
      };
      if (postValue) {
        this.setState({ SubjectPerformanceRecord: postValue });
      }
    } else {
      this.setState({ SubjectPerformanceRecord: null });
    }
  }
  render() {
    const { getDuration,isSubjectClassLoader,isteacherSubjectPerdormanceLoader } = this.props;
    const { correctSubjectList, addSAClassName, SubjectPerformanceRecord, subjectperId } =
      this.state;
      const isTopicAnalysisLoaderVisible = { display: isSubjectClassLoader ? "block" : isteacherSubjectPerdormanceLoader ? "block" :'none' };
    return (
      <div>
        <div className={`card card-topline-red ${addSAClassName}`}>
        <div className="overlay" style={isTopicAnalysisLoaderVisible}><div className='custom-spinner-individual'></div></div>
          <div className="card-head">
            <header>Subject Performance</header>
            <div className="tools">
              <div className="d-flex">
                <div className="ml-2 mt-1">
                  <span
                    className="fa fa-repeat btn-color box-refresh pointer"
                    onClick={this.refreshSubjectPerformance}
                  ></span>
                  <span
                    className="t-collapse btn-color fa fa-chevron-down pointer"
                    onClick={this.cardSubjectPerformanceDownUp}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row p-3 pb-0">
            <div className="col-md-6">
              {correctSubjectList ? (
                <select
                  name="subjectList"
                  id=""
                  className="form-control"
                  value={subjectperId}
                  onChange={this.getSubjectFilterSubject}
                >
                  {correctSubjectList.map((items: any) => (
                    <option value={items.id}> {items.name} </option>
                  ))}
                </select>
              ) : null}
            </div>
            <div className="col-md-6">
              {getDuration ? (
                <select
                  name="duration"
                  id=""
                  className="form-control"
                  value={this.state.subjectFilterYear}
                  onChange={this.getYearSubjectChange}
                >
                  {getDuration.map((items: any) => (
                    <option value={items.value}> {items.value} </option>
                  ))}
                </select>
              ) : null}
            </div>
          </div>
          <div className="card-body no-padding height-9">
            {SubjectPerformanceRecord &&
            SubjectPerformanceRecord !== null &&
            SubjectPerformanceRecord !== undefined ? (
              <div className="row">
                <Bar
                  data={{
                    labels: SubjectPerformanceRecord.subject_name_Perform,
                    datasets: [
                      {
                        backgroundColor:
                          SubjectPerformanceRecord.color_code_Perform,
                        borderColor:
                          SubjectPerformanceRecord.color_code_Perform,
                        borderWidth: 2,
                        data: SubjectPerformanceRecord.performance_Perform,
                        label: SubjectPerformanceRecord.labelNameList,
                      },
                    ],
                  }}
                  height={115}
                  options={option}
                />
              </div>
            ) : (
              <CommonLoader />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    getProfile: state.profile.profileData,
    getChangeYearData:state.profile.getYear,
    stopYearDate:state.profile.yearStatusFails,
    getSubjectPerform: state.dashboard.getSubjectPerformance,
    getDuration: state.profile.getDuration,
    loading: state.dashboard.loading,
    TeacherCorrectSubjectList: state.subjects.TeacherCorrectSubject,
    isSubjectClassLoader:state.subjects.isSubjectClassLoader,
    isteacherSubjectPerdormanceLoader:state.dashboard.isteacherSubjectPerdormanceLoader
  };
};

export default connect(mapStateToProps, {
  fetchSubjectPerformance,
  GetTeacherCorrectSubject,
  DurationListGet, stopYearData })(SubjectPerformanceViews);
