import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchStudentProfileLine } from '../../../store/student/Actions';
import SpinnerLoader from '../../../components/spinner/SpinnerLoader';
import { VictoryLine, createContainer, VictoryChart, VictoryTooltip } from "victory";
import moment from 'moment';
import { fetchProfilePost } from '../../../store/profile/Actions';
import { stopYearData, stopSubComponentYear } from '../../../store/profile/Actions'
import {lineChartNameColor} from '../../../services/Constants';

interface propsTypes {
    getChangeYearData?:any;
	stopYearDate?:any;
    getStudentInfoDetails?: any;
    subComponentStatus?:any
    stopSubComponentYear:(postValue?: any)=>any;
    fetchStudentProfileLine: (postValue: any) => any;
    fetchProfilePost: () => any;
    stopYearData:()=>any;
    loading?: any;
    studentProfileLine: any;
    loginProfile?: any;
}
const VictoryZoomVoronoiContainer: any = createContainer("zoom", "voronoi")
interface stateTypes {
    attendanceDataset: any,
    perfomanceDataset: any,
    subjectDataset: any,
    legendNameList: any,
    attendanceDatasetHidden:any,
    perfomanceDatasetHidden:any,
    subjectDatasetHidden:any,
    AttendanceStatu?:boolean,
    PerformanceStatu?:boolean,
    labelClassName?:string,
    subjectNameList?:any,
    subjectStatus?:any,
    attendanceColor?:string,
    perfomanceColor?:string,
    academicYear?:any
}
export class StudentLineChart extends Component<propsTypes, stateTypes> {
    constructor(props: any) {
        super(props);
        this.state = {
            attendanceDataset: [],
            perfomanceDataset: [],
            subjectDataset: [],
            legendNameList: [],
            attendanceDatasetHidden:false,
            perfomanceDatasetHidden:false,
            subjectDatasetHidden:false,
            AttendanceStatu:true,
            PerformanceStatu:true,
            labelClassName:'lineChartLabelnormal',
            subjectNameList:[],
            subjectStatus:true,
            attendanceColor:'',
            perfomanceColor:'',
            academicYear:null
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.UpdateYearDate();
    }
    
  componentWillUpdate(nextProps:any, nextState:any) {
    const {stopYearDate, subComponentStatus} = this.props;
    if(subComponentStatus === true){
        this.props.stopSubComponentYear()
        this.UpdateYearDate(this.props.getChangeYearData)
    }else if(stopYearDate === true){
  this.UpdateYearDate(this.props.getChangeYearData)
    }
}
componentDidUpdate() {
    const {stopYearDate, subComponentStatus} = this.props;
    if(subComponentStatus === true){
        this.props.stopSubComponentYear()
    }else if(stopYearDate === true){
        this.props.stopYearData()
    }
}
UpdateYearDate(getYear?:any){
if(getYear && getYear !== undefined && getYear !== null){
    this.setState({academicYear:getYear})
    this.getStudentFullDetails(getYear)
}else {
    this.setState({academicYear:this.props.getChangeYearData})
    this.getStudentFullDetails(this.props.getChangeYearData)
}
}
    getStudentFullDetails(getYear?:any) {
        let getStudentId: any = this.props.getStudentInfoDetails;
            const { loginProfile } = this.props;
            if (loginProfile && loginProfile !== undefined && loginProfile !== null) {
                if (loginProfile.usertype === 1) {
                    if (getStudentId) {
                        let postValue: any = {
                            academic_year:getYear,
                            class_id: getStudentId.class_id,
                            card_id: getStudentId.card_id,
                            school_id: getStudentId.school_id
                        }
                        this.props.fetchStudentProfileLine(postValue).then((res:any)=>{
                            this.getSubjectDataset();
                            this.getPerfomanceDataset();
                            this.getAttendanceDataset();
                        });
                    }
                } else if (loginProfile.usertype === 2 || loginProfile.usertype === 3) {
                    let postValue: any = {
                        academic_year:getYear,
                        class_id: getStudentId.class_id,
                        card_id: getStudentId.card_id
                    }
                    this.props.fetchStudentProfileLine(postValue).then((res:any)=>{
                        this.getSubjectDataset();
                        this.getPerfomanceDataset();
                        this.getAttendanceDataset();
                        });;
                }
            }

    }
    // This is function used to call perfomance dataset
    getPerfomanceDataset(){
        let perfomanceDataset: any = []
        this.setState({ perfomanceDataset: [], perfomanceColor:'' })
        if (this.props.studentProfileLine && this.props.studentProfileLine !== undefined && this.props.studentProfileLine !== null) {
            if (this.props.studentProfileLine.performance) {
                this.props.studentProfileLine.performance.values.forEach((performanceData: any, performanceIndex: any) => {
                    let performanceDate: any = moment(performanceData.date, "DD-MM-YYYY")
                    let performanceObj: any = {}
                    performanceObj['x'] = new Date(performanceDate.format('YYYY'), performanceDate.format('M') - 1, performanceDate.format('D'))
                    performanceObj['y'] = performanceData.value
                    performanceObj['labelName'] = `Performance - ${performanceData.value}`
                    perfomanceDataset.push(performanceObj)
                    performanceData['performance_data'] = perfomanceDataset;
                })
                this.setState({ perfomanceDataset: perfomanceDataset, perfomanceColor:this.props.studentProfileLine.performance.color_code })
            }else {
                this.setState({ perfomanceDataset: [], perfomanceColor:'' })
            }
        }
    }
// This is function used to call Attendance dataset
    getAttendanceDataset(){
        let attendanceDataset: any = []
        if (this.props.studentProfileLine && this.props.studentProfileLine !== undefined && this.props.studentProfileLine !== null) {
            this.setState({ attendanceDataset: [], attendanceColor:'' })   
            if (this.props.studentProfileLine.attendance) {
                        this.props.studentProfileLine.attendance.values.forEach((attendanceData: any, attendanceIndex: any) => {
                            let attendanceDate: any = moment(attendanceData.date, "DD-MM-YYYY")
                            let attendanceObj: any = {
                                x: new Date(attendanceDate.format('YYYY'), attendanceDate.format('M') - 1, attendanceDate.format('D')),
                                y: attendanceData.value,
                                labelName: `Attendance - ${attendanceData.value}`
                            }
                            attendanceDataset.push(attendanceObj)
                            attendanceData['attendance_dataset'] = attendanceDataset;
                        })
                        this.setState({ attendanceDataset: attendanceDataset, attendanceColor:this.props.studentProfileLine.attendance.color_code })
                }else{
                    this.setState({ attendanceDataset: [], attendanceColor:'' })
                }
        }
    }
    getSubjectDataset() {
        let legendNameList: any = [];
        if (this.props.studentProfileLine && this.props.studentProfileLine !== undefined && this.props.studentProfileLine !== null) {
                if (this.props.studentProfileLine.subjects) {
                    if (this.props.studentProfileLine.subjects) {
                        let subjectNameList: any = [];
                        this.props.studentProfileLine.subjects.forEach((subjectName: any, index: any) => {
                           
                            let postValue:any = {
                                subjectName: subjectName.subject_name,
                                subjectId:subjectName.subject_id
                            }
                            subjectNameList.push(postValue)
                            this.setState({subjectNameList:subjectNameList})
                        })
                        
                        let subjectlabel: any = this.props.studentProfileLine.subjects;
                        if(subjectlabel){
                            subjectlabel.forEach((items:any)=>{
                            let lineChartSubject:any = [];
                            items.values.forEach((getDate:any)=>{
                                let subjectDate: any = moment(getDate.date, "DD-MM-YYYY")
                                let subjecteObj: any = {}
                                subjecteObj['x'] = new Date(subjectDate.format('YYYY'), subjectDate.format('M') - 1, subjectDate.format('D'))
                                subjecteObj['y'] = getDate.value
                                subjecteObj['labelName'] = `${items.subject_name} - ${getDate.value}`
                                // subjectDataset.push(subjecteObj)
                                lineChartSubject.push(subjecteObj);
                                items['subject_list'] = lineChartSubject;
                                items['is_visible']  = true
                            })
                            })
                            this.setState({subjectDataset:subjectlabel})
                        }else {
                            
                            this.setState({subjectDataset:[]})
                        }
                    }
                     if (this.props.studentProfileLine.subjects) {
                        legendNameList = [];
                        this.props.studentProfileLine.subjects.forEach((legendName: any) => {
                            let nameList: any = {
                                name: legendName.subject_name,
                                color_code: legendName.color_code
                            }
                            legendNameList.push(nameList)
                        })
                        this.setState({ legendNameList: legendNameList })
                    }
                }
        }
    }
 
    getHiddenLine = (getValue:any, index:any) =>{
        const { AttendanceStatu, PerformanceStatu, legendNameList} = this.state;
        let performancecurrentStatu:any
        let AttendancecurrentStatu:any
        lineChartNameColor.forEach((items:any, ind:any)=>{
            if(items.name === getValue){
                let lineActive: any = document.getElementById(index);
                if (lineActive.className === 'mr-2 pointer lineChartLabel') {
                    lineActive.classList.remove('lineChartLabel');
                }else{
                    lineActive.classList.add('lineChartLabel');
                }
            }
        })
        legendNameList.forEach((items:any, ind:any)=>{
            if(items.name === getValue){
                let lineActive: any = document.getElementById(index);
                if (lineActive.className === 'mr-2 pointer lineChartLabel') {
                    lineActive.classList.remove('lineChartLabel');
                }else{
                    lineActive.classList.add('lineChartLabel');
                }
            }
        })
            if(getValue === "Attendance"){
                performancecurrentStatu = PerformanceStatu;
               if(AttendanceStatu === true) {
                this.setState({AttendanceStatu:false, PerformanceStatu:performancecurrentStatu})
               }else if(AttendanceStatu === false){
                performancecurrentStatu = PerformanceStatu;
                this.setState({AttendanceStatu:true, PerformanceStatu:performancecurrentStatu})

               }
            }else if(getValue === "Performance"){
                if(PerformanceStatu === true) {
                    AttendancecurrentStatu = AttendanceStatu;
                    this.setState({PerformanceStatu:false, AttendanceStatu:AttendancecurrentStatu})
                   }else if(PerformanceStatu === false){
                    AttendancecurrentStatu = AttendanceStatu;
                    this.setState({PerformanceStatu:true, AttendanceStatu:AttendancecurrentStatu})
                   }
            }else {
            const {subjectDataset} = this.state;
            if(subjectDataset.length > 0 && subjectDataset !== undefined && subjectDataset !== null){
                subjectDataset.forEach((subjectName:any, index:any) => {
                    let getSubjectList:any = subjectDataset.find((items:any)=> items.subject_name === getValue)
                    if(getSubjectList && getSubjectList !== undefined){
                        if(getSubjectList.subject_name === getValue){
                            if(getSubjectList.is_visible === true && getSubjectList.subject_name === getValue){
                                    subjectDataset.forEach((subjectName:any, index:any) => {
                                        if(subjectName.subject_name === getValue){ 
                                            subjectName['is_visible'] = false
                                        }
                                    })
                               
                               }else if(getSubjectList.is_visible === false && getSubjectList.subject_name === getValue){
                                subjectDataset.forEach((subjectName:any, index:any) => {
                                    if(subjectName.subject_name === getValue){ 
                                        subjectName['is_visible'] = true
                                    }
                                })
                               }
                           }
                    }            
                })
                this.setState({subjectDataset:subjectDataset})
                }
            } 
    }
    render() {
        const { loading } = this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        const { attendanceDataset,
            perfomanceDataset,
            subjectDataset,
            legendNameList, AttendanceStatu, PerformanceStatu, perfomanceColor, attendanceColor } = this.state;
        return (
            <div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-topline-red">
                            <div className="pl-4 pt-3">
                            </div>

                            <div className="pl-5 pr-5">
                                <div>
                                    {lineChartNameColor?
                                    lineChartNameColor.map((items:any, index:any)=>(
                                        <span className="mr-2 pointer"  style={{color:items.color_code}} id={index+items.name} onClick={() => this.getHiddenLine(items.name, index+items.name)}><i className="fa fa-circle" aria-hidden="true"></i> {items.name}</span>
                                    ))
                                    :null}
                                {legendNameList ?
                                legendNameList.map((items:any, index:any)=>(
                                    <>
                                    <span className="mr-2 pointer"  style={{color:items.color_code}} id={index+items.name} onClick={() => this.getHiddenLine(items.name, index+items.name)}><i className="fa fa-circle" aria-hidden="true"></i> {items.name}</span>
                                    </>
                                )):null}
                                </div>
                                <VictoryChart
                                    width={1200}
                                    height={300}
                                    scale={{ x: "time" }}
                                    padding={{top: 20, left: 50, right: 50, bottom: 40}}
                                    domain={{y: [0, 100] }}
                                    domainPadding={{ x: 10, y:30 }}
                                    containerComponent={
                                        <VictoryZoomVoronoiContainer
                                        zoomDimension="x"
                                            labels={({ datum }:any) => `${datum.labelName}`}
                                            labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{fill: "white"}}/>}
                                        />
                                    }
                                >
                                    {attendanceDataset && AttendanceStatu === true ?
                                    <VictoryLine
                                        interpolation="natural"
                                        style={{
                                            data: { stroke: `${attendanceColor}` },
                                            parent: { border: `"1px solid ${attendanceColor}`}
                                        }}
                                        data={attendanceDataset}
                                    />:null}
                                    {perfomanceDataset && PerformanceStatu === true ?
                                    <VictoryLine
                                        interpolation="natural"
                                        style={{
                                            data: { stroke: `${perfomanceColor}` },
                                            parent: { border: `1px solid ${perfomanceColor}`}
                                        }}
                                        data={perfomanceDataset}
                                    />:null}
                                    {subjectDataset?
                                     // eslint-disable-next-line
                                subjectDataset.map((subjectList:any, index:any)=>{
                                    if(subjectList.is_visible === true){
                                        return(
                                            <VictoryLine
                                            interpolation="natural"
                                            style={{
                                                data: { stroke: `${subjectList.color_code}` },
                                                parent: { border: `1px solid ${subjectList.color_code}`}
                                            }}
                                            data={subjectList.subject_list}
                                        />
                                    )
                                    }
                            })
                                    :null}
                                </VictoryChart>
                            </div>


                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.student.loading,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
		subComponentStatus:state.profile.subComponentStart,
        getStudentInfoDetails: state.profile.AttendanceStudentView,
        studentProfileLine: state.student.studentProfileLine,
        loginProfile: state.profile.profileData
    }
}

export default connect(mapStateToProps, { fetchStudentProfileLine, fetchProfilePost, stopYearData, stopSubComponentYear })(StudentLineChart)
