import React from 'react';
import { connect } from 'react-redux';
import { SubjectFieldsType, CategoryFieldsType, SubjectTypes } from '../../store/subject/Types';
import { RouteComponentProps } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import { fetchCategoryPost, fetchSubject, editSubject, getSubjectList  } from '../../store/subject/Actions'
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import { Link } from 'react-router-dom';
import { Button, FormGroup, MenuItem, Checkbox } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { EditSubjectValidation } from './SubjectValidation';
import { getAllSchoolNameList } from '../../store/usermanage/Actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {icon, checkedIcon} from '../../components/checkBoxTypes';
import { studentValida } from '../../services/Constants';

interface subjecteditField {
    getCategoryDetails: any;
    getAllCategoryDetails: any;
    getCategoryList: any;
    categoryData: any;
    electData: any;
    getAllSubjectDetails: any;
    getSubjectList: any;
    dataValue: any;
}
export interface PostsListProps extends RouteComponentProps<OwnPropsParams> {
    loading: boolean;
    subjectData: SubjectTypes;
    subjectIdList: any;
    categoryDetails: CategoryFieldsType[],
    editSubject: (subject: SubjectFieldsType) => any;
    fetchCategoryPost: () => void;
    fetchSubject: (id: number) => any;
    getAllSchoolNameList:()=>any;
    getSubjectList:() => any;
    errorMessage: any;
    schoolNameList:any;
    getSubjectItems:any
}
type AllProps = PostsListProps & subjecteditField;
class EditSubject extends React.Component<AllProps, SubjectFieldsType> {
    formik:any;
    constructor(props:any){
        super(props);
        this.state = {
            school_name:[],
            get_Subject_id:null,
            get_Subject:[],
            getSubjectEditDetail:null
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.props.fetchCategoryPost();
        this.props.getSubjectList();
        this.props.getAllSchoolNameList();
       this.getSubjectDetails();
    }
    getSubjectDetails(){
        this.props.fetchSubject(Number(this.props.match.params.id)).then((res:any)=>{
            const {subjectIdList, schoolNameList} = this.props;
            if(subjectIdList.school_list.length > 0 && subjectIdList.school_list !== null && subjectIdList.school_list !== undefined){
                let schoolName:any = [];
                subjectIdList.school_list.forEach((items:any)=>{
                    let getClassId:any = schoolNameList.find((item:any)=> item.id === parseInt(items.id)) 
                    if(getClassId){
                        schoolName.push(getClassId)
                    }
                })
                this.setState({school_name:schoolName, get_Subject_id:subjectIdList.id})
            }
            if(this.props.subjectIdList && this.props.subjectIdList !== null){
                let postValue:any ={
                        id: this.props.subjectIdList.id,
                        SubjectSchoolId: this.props.subjectIdList.school_id,
                        SubjectshortName: this.props.subjectIdList.short_name,
                        SubjectName: this.props.subjectIdList.name,
                        SubjectCategory: this.props.subjectIdList.category,
                        name:this.props.subjectIdList.name
                    }
                    this.setState({getSubjectEditDetail:postValue})
            }else {
                this.setState({getSubjectEditDetail:null})
            }
        });
    }
       // get school name list
       getSchoolNameList = (getValue:any) =>{
        if(getValue && getValue.length > 0 && getValue !== null && getValue !== undefined){
            let getSchoolName:any = [];
            getValue.forEach((items:any)=>{
                getSchoolName.push(items.id)
            })
            this.setState({school_name:getSchoolName});
        }else{
            this.setState({school_name:[]});
        }
    }
    getSubjectValue = (e:any) => {
        const {getSubjectItems} = this.props;
        const {value} = e.target;
        if(getSubjectItems && getSubjectItems !== null && getSubjectItems !== undefined){
            getSubjectItems.forEach((SubjectName:any) => {
                if(SubjectName.name === value){
                    this.formik.setFieldValue('SubjectshortName', SubjectName.short_name)
                    this.setState({get_Subject_id:SubjectName.id})
                }
            })
        }
    }
    render() {
        const { loading, schoolNameList, getSubjectItems } = this.props;
        const {school_name, getSubjectEditDetail} = this.state;
        const loadingEditSubject = { display: loading ? "block" : "none" };
        const getToken = localStorage.getItem('token');
        if (!getToken) {
            history.push("/");
        }
        const getCategoryDetails:any = this.props.categoryDetails;
        let categorySubjectEdit: any;
        if (getCategoryDetails) {
            categorySubjectEdit = getCategoryDetails.data;
        }
        const initialSubjectEdit: any = getSubjectEditDetail;
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Subject']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['Subject']}
                                baseURL={['subject']}
                                mainPageTitle={['Edit Subject']} />
                            {getSubjectEditDetail && getSubjectEditDetail !==null && categorySubjectEdit ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-box">
                                            <div className="card-head">
                                                <header>Map Subject</header>
                                            </div>
                                            <div className="card-body">
                                            <Formik
                                 ref={node=>this.formik = node}
                                initialValues={initialSubjectEdit}
                                onSubmit={(values: any, actions) =>{
                                    const {school_name} = this.state
                                    const PostSubjectEdit: any = {
                                        id: getSubjectEditDetail.id,
                                        school_list: school_name,
                                        short_name: values.SubjectshortName,
                                        name: values.SubjectName,
                                        category: values.SubjectCategory
                                    }
                                    this.props.editSubject(PostSubjectEdit).then((res:any)=>{
                                        if(this.props.errorMessage.data){
                                            this.formik.setFieldError("SubjectName", this.props.errorMessage.data.name);
                                            this.formik.setFieldError("SubjectshortName", this.props.errorMessage.data.short_name);            
                                        }  
                                    })
                                }}
                                validationSchema={EditSubjectValidation}
                                render={({
                                    values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue,setFieldTouched
                                }: FormikProps<any>) => {
                                    const isEmpty = (!values.SubjectName || !values.SubjectshortName || !values.SubjectCategory);
                                    return(
                                    <form onSubmit={handleSubmit}>
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6 p-t-20">
                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                    <FormGroup>
                                                    <Field
                                                            label={<><span>Subject Name</span><span className='formmandatorycolor'> *</span></>}
                                                            select
                                                            type="text"
                                                            name="name"
                                                            component={TextField}
                                                            className="textfield__input"
                                                            disabled={true}
                                                            onChange={(e:any) => {
                                                                setFieldValue('name', e.target.value)
                                                                this.getSubjectValue(e)
                                                              }}  
                                                        >
                                                          {getSubjectItems.map((item: any) => (
                                                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                                                ))}
                                                        </Field>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col-md-6 p-t-20">
                                                <div>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Short Name</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="SubjectshortName"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" row">
                                            <div className="col-md-6">
                                                <div>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-2">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Category</span><span className='formmandatorycolor'> *</span></>}
                                                                name="SubjectCategory"
                                                                select
                                                                component={TextField}
                                                                className="textfield__input"
                                                                fullwidth="true"
                                                                disabled={true}
                                                            >
                                                                {categorySubjectEdit.map((item: any) => (
                                                                    <MenuItem value={item.value}>{item.value}</MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="col-md-6">
                                                {schoolNameList?
                                                <Autocomplete
                                                fullWidth
                                                multiple
                                                id="school_name"
                                                options={schoolNameList? schoolNameList:[]}
                                                disableCloseOnSelect
                                                noOptionsText={studentValida.noRecordData}
                                                defaultValue={school_name} 
                                                getOptionLabel={(option:any) => option.school_name}
                                                onChange={(e:any, getvalue:any) => {
                                                    setFieldValue('school_name', e.target.value);
                                                    this.getSchoolNameList(getvalue);
                                                    setFieldTouched('SubjectName',true);
                                                }}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.school_name}
                                                    </React.Fragment>
                                                )}
                                                className="mb-0 mt-1"
                                                renderInput={(params) => {
                                                    return(
                                                    <Field component={TextField} {...params} label={"Search School Name"} name="school_name"/>
                                                )}}
                                                />
                                                :null}
                                            
                                            </div>
                                        </div>
                                        <div className="text-right mb-3 mr-2 mt-4">
                                            <Button 
                                            className="btn btn-pink mr-1 ml-1" 
                                            disabled={isEmpty || isValidating ||  !touched.SubjectName ||
                                                !!(errors.SubjectCategory && touched.SubjectCategory)||
                                                !!(errors.SubjectshortName && touched.SubjectshortName) || 
                                                !!(errors.SubjectName && touched.SubjectName)}  type="submit">Submit</Button>
                                            <Link to="/subject">
                                            <Button 
                                            className="btn btn-default mr-1 ml-1 ">Cancel</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                                )}
                            }
                                />                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <div><SpinnerLoader /></div>}
                        </div>
                    </div>
                </div>
                <div style={loadingEditSubject}><SpinnerLoader /></div>
            </div>

        );
    }
}

interface OwnPropsParams {
    id: string;
}
const mapStateToProps = (state:any, ownProps: RouteComponentProps<OwnPropsParams>) => {
    return {
        categoryDetails: state.subjects.category,
        loading: state.subjects.loading,
        subjectData: state.subjects.items[Number(ownProps.match.params.id)],
        subjectIdList: state.subjects.editSubjectDetails,
        errorMessage: state.subjects.errors,
        getSubjectItems: state.subjects.getAllSubjectList,
        schoolNameList: state.userManage.category
    };
};
export default connect(
    mapStateToProps, { editSubject, fetchCategoryPost, fetchSubject, getAllSchoolNameList, getSubjectList  }
)(EditSubject);
