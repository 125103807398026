export interface QuestionState {
  items?: any,
  loading?: boolean,
  isAuthenticated?: boolean | null,
  modelPop?: boolean,
  errors?: any;
  page: number,
  per_page: number,
  records: any,
  total: number,
  QuestionId: string,
  getQuestionList?: any,
  teacherOverallPerformance?: any,
  recentFeedbackList?: any,
  recentQuizReport?: any,
  feedbackBarChart?: any,
  FeedbackPieChart?: any,
  feedbackStudent?: any,
  feedbackSubject?: any,
  topicAnalysisReport?: any,
  BarchartReport?: any,
  QuizzesStudentReport?: any,
  QuestionAnswersReport?: any,
  CalculationsReport?: any,
  questionReportView?: any,
  singleQuizStudent?: any,
  singleQuizQuestionList?: any,
  getTeacherSubjectList?: any,
  studentRecentQuiz?: any,
  studentViewAnswers?: any,
  downloadFiles?:any
}

export interface QuestionTypes {
  acsOrder?: boolean,
  recordPage?: any,
  academicYear?: any,
  search: string,
  grade?: string,
  standard?: string,
  hasMore: boolean,
  SortOrderData: string,
  OrderNameData: string,
  page?: any,
  page_no?: any,
  sort_by?: string,
  sort_class_name?: string,
  sort_title_name?: string
}


//Schools fetch details
export enum QuestionActionTypes {
  FETCH_QUESTION = 'FETCH_QUESTION',
  FETCH_QUESTION_SUCCESS = 'FETCH_QUESTION_SUCCESS',
  FETCH_QUESTION_FAIL = 'FETCH_QUESTION_FAIL',
  FETCH_QUESTION_ID = 'FETCH_QUESTION_ID',
  FETCH_QUESTION_SUCCESS_ID = 'FETCH_QUESTION_SUCCESS_ID',
  FETCH_QUESTION_FAIL_ID = 'FETCH_QUESTION_FAIL_ID',
  ADD_QUESTION = 'ADD_QUESTION',
  ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS',
  ADD_QUESTION_FAIL = 'ADD_QUESTIONL_FAIL',
  EDIT_QUESTION = 'EDIT_QUESTION',
  EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS',
  EDIT_QUESTION_FAIL = 'EDIT_QUESTION_FAIL',
  DELETE_QUESTION = 'DELETE_QUESTION',
  DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL',
  Teacher_Overall_Performance = 'Teacher_Overall_Performance',
  Teacher_Overall_Performance_SUCCESS = 'Teacher_Overall_Performance_SUCCESS',
  Teacher_Overall_Performance_FAIL = 'Teacher_Overall_Performance_FAIL',
  Recent_Feedback_List = 'Recent_Feedback_List',
  Recent_Feedback_List_Success = 'Recent_Feedback_List_Success',
  Recent_Feedback_List_Fail = 'Recent_Feedback_List_Fail',
  Recent_Quiz_Report = 'Recent_Quiz_Report',
  Recent_Quiz_Report_Success = 'Recent_Quiz_Report_Success',
  Recent_Quiz_Report_Fail = 'Recent_Quiz_Report_Fail',
  Feedback_Bar_Chart = 'Feedback_Bar_Chart',
  Feedback_Bar_Chart_Success = 'Feedback_Bar_Chart_Success',
  Feedback_Bar_Chart_Fail = 'Feedback_Bar_Chart_Fail',
  Feedback_Pie_Chart = 'Feedback_Pie_Chart',
  Feedback_Pie_Chart_Success = 'Feedback_Pie_Chart_Success',
  Feedback_Pie_Chart_Fail = 'Feedback_Pie_Chart_Fail',
  Feedback_Student = 'Feedback_Student',
  Feedback_Student_Success = 'Feedback_Student_Success',
  Feedback_Student_Fail = 'Feedback_Student_Fail',
  Feedback_Subject = 'Feedback_Subject',
  Feedback_Subject_Success = 'Feedback_Subject_Success',
  Feedback_Subject_Fail = 'Feedback_Subject_Fail',
  Topic_Analysis_Report = 'Topic_Analysis_Report',
  Topic_Analysis_Report_Success = 'Topic_Analysis_Report_Success',
  Topic_Analysis_Report_Fail = 'Topic_Analysis_Report_Fail',
  Quizzes_Student_Report = 'Quizzes_Student_Report',
  Quizzes_Student_Report_Success = 'Quizzes_Student_Report_Success',
  Quizzes_Student_Report_Fail = 'Quizzes_Student_Report_Fail',
  Question_answers_Report = 'Question_answers_Report',
  Question_answers_Report_Success = 'Question_answers_Report_Success',
  Question_answers_Report_Fail = 'Question_answers_Report_Fail',
  Calculations_Report = 'Calculations_Report',
  Calculations_Report_Success = 'Calculations_Report_Success',
  Calculations_Report_Fail = 'Calculations_Report_Fail',
  Bar_chart_Report = 'Bar_chart_Report',
  Bar_chart_Report_Success = 'Bar_chart_Report_Success',
  Bar_chart_Report_Fail = 'Bar_chart_Report_Fail',
  Question_Report_View = 'Question_Report_View',
  Question_Report_View_Success = 'Question_Report_View_Success',
  Question_Report_View_Fail = 'Question_Report_View_Fail',
  Single_Quiz_Student = 'Single_Quiz_Student',
  Single_Quiz_Student_Success = 'Single_Quiz_Student_Success',
  Single_Quiz_Student_Fail = 'Single_Quiz_Student_Fail',
  Single_Quiz_Question_List_List = 'Single_Quiz_Question_List',
  Single_Quiz_Question_List_Success = 'Single_Quiz_Question_List_Success',
  Single_Quiz_Question_List_Fail = 'Single_Quiz_Question_List_Fail',
  Teacher_Subject_List = 'Teacher_Subject_List',
  Teacher_Subject_List_Success = 'Teacher_Subject_List_Success',
  Teacher_Subject_List_Fail = 'Teacher_Subject_Listt_Fail',
  Student_Recent_Quiz = 'Student_Recent_Quiz',
  Student_Recent_Quiz_Success = 'Student_Recent_Quiz_Success',
  Student_Recent_Quiz_Fail = 'Student_Recent_Quiz_Fail',
  Student_View_Answers = 'Student_View_Answers',
  Student_View_Answers_Success = 'Student_View_Answers_Success',
  Student_View_Answers_Fail = 'Student_View_Answers_Fail',
  Reset_Question_Details = 'Reset_Question_Details',
  DOWNLOAD_QUESTION_FORMAT = 'DOWNLOAD_QUESTION_FORMAT',
  DOWNLOAD_QUESTION_FORMAT_SUCCESS = 'DOWNLOAD_QUESTION_FORMAT_SUCCESS',
  DOWNLOAD_QUESTION_FORMAT_FAIL = 'DOWNLOAD_QUESTION_FORMAT_FAIL',
  UPLOAD_QUESTION = 'UPLOAD_QUESTION',
  UPLOAD_QUESTION_SUCCESS = 'UPLOAD_QUESTION_SUCCESS',
  UPLOAD_QUESTION_FAIL = 'UPLOAD_QUESTION_FAIL',
}