import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Button, FormGroup, MenuItem } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import BreadCrumb from '../../components/BreadCrumb';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import { TeacherClassMappingPost } from '../../store/teacher/Actions';
import { TextField } from 'formik-material-ui';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import history from '../../History';
import {onGetData} from '../../store/classes/Actions';
import {onGetValue} from '../../services/Config';
import { stopYearData } from '../../store/profile/Actions';
import * as yup from 'yup';
import { userFormValidations } from '../../services/Constants';

export type OwnTeacherFormProps = {
    getChangeYearData?:any;
	stopYearDate?:any;
    TeacherClassMappingPost: (teacherMap: any) => any;
    fetchGetAllClassList: (postValue:any) => any;
    onGetData:(postValue:any) => any;
    stopYearData:()=>any;
    loading: boolean; 
    getClassList:boolean;
    getTeacherViewData:any;
    schoolIdDetails:any;
    mappingClassStatu:any,
    mappingTeacherId:any;
    profileInfo:any;
    getTeacherClassList:any;
};
export interface addClasslist {
    ClassList:string
}
const initialClassValues:addClasslist = {
    ClassList:''
}

const classInChargeValidation = yup.object({
    ClassList: yup.string()
        .required(userFormValidations.sectionRequiredMsg)
})

interface stateTypes {
    classNameList?:any,
    academicYear?:any
}
export class AddClassInCharge extends Component<OwnTeacherFormProps, stateTypes> {
    getClassIncharge:any = [];
    constructor(props: any) {
        super(props);
        this.state = {
            classNameList:[],
            academicYear:null
        }
    }
    componentDidMount(): void {
       this.UpdateYearDate()
    }
    componentWillUpdate() {
        const {stopYearDate} = this.props;
        if(stopYearDate === true){
            this.UpdateYearDate(this.props.getChangeYearData)
        }
    }

    componentDidUpdate() {
        const {stopYearDate} = this.props;
        if(stopYearDate === true){
            this.props.stopYearData()
        }
    }

    UpdateYearDate(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.getClassinChargeSubject(getYear)
            this.setState({academicYear:getYear})
        }else {
            this.getClassinChargeSubject(this.props.getChangeYearData)
            this.setState({academicYear:this.props.getChangeYearData})
        }
    }
getClassinChargeSubject(getYear?:any){
    window.scrollTo(0, 0);
    const ClassIncharge:any = {
        academic_year:getYear
    }
    let getData:any = {
        data:ClassIncharge,
        URL:onGetValue.getClassList
    }
    this.props.onGetData(getData).then((res:any)=>{
        const {getClassList} = this.props;
        this.setState({classNameList:getClassList})
    });
}
    getClassInchargeList(getValue?:any){     
        this.getClassIncharge = [];
        if(getValue) {
            this.getClassIncharge.push(String(getValue.id))        
         }
    }

    onSubmitClassIncharge = (values: any) => {
        const {academicYear} = this.state;
        let classIncharge:any = [];
        if(values){
            classIncharge = [values.ClassList]
        }
        if(classIncharge.length) {
            const ClassIncharge: any = {
                academic_year: academicYear,
                teacher_id: this.props.getTeacherViewData.ldap_id,
                class_incharge: classIncharge
            }
            this.props.TeacherClassMappingPost(ClassIncharge).then((res:any)=>{
                const {mappingClassStatu, mappingTeacherId} = this.props;
                const { profileInfo } = this.props;
                if(mappingClassStatu === true){
                    if(profileInfo && profileInfo !== undefined && profileInfo !== null){
                        if(profileInfo.usertype === 2) {
                            history.push(`/teacher/${mappingTeacherId}`);
                           
                        } else if(profileInfo.usertype === 3) {
                            history.push('/teachers_profile');
                           
                        }
                    }
                }
            })
        }
       
    }
    render() {
        const { loading } = this.props;
        const {classNameList} = this.state;
        const loadingTeacher = { display: loading  ? "block" : "none" };
        return (
            <div>
                  <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb 
                            titleName={['Teacher']} 
                            homeName={['Home']} 
                            url={['dashboard']} 
                            baseName={['Teacher']} 
                            baseURL={['teacher']} 
                            mainPageTitle={['Add Class Incharge']}/>
                             <div className="row">
                        <div className="col-md-12">
                            <div className="card-box">
                                <div className="card-head">
                                    <header>Add Class Incharge</header>
                                    <div className="tools">
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Formik
                                                initialValues={initialClassValues}
                                                onSubmit={this.onSubmitClassIncharge}
                                                validationSchema={classInChargeValidation}
                                                render={({
                                                    values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                                                }: FormikProps<any>) => (
                                                    <form onSubmit={handleSubmit} className="ui form">  
                                                    <div className="row">      
                                                            <div className="col-md-12 p-t-20">
                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                <FormGroup>
                                                                {classNameList?
                                                                <FormGroup>
                                                                <Field 
                                                                label={<><span>Section</span><span className='formmandatorycolor'> *</span></>}
                                                                name="ClassList"  
                                                                select
                                                                component={TextField}        
                                                                disabled={false} 
                                                                onChange={(e:any) => {
                                                                    setFieldValue('ClassList', e.target.value)
                                                                  }}    
                                                                >
                                                                    {
                                                                        classNameList.map( (item:any) => (
                                                                            <MenuItem value={item.id}>{item.grade_standard}</MenuItem>
                                                                        ))
                                                                    }
                                                                    
                                                                </Field>                         
                                                                </FormGroup>
                                                                    :null}
                                                                </FormGroup> 
                                                                </div>
                                                            </div>
                                                        </div>         
                                                        <div className="text-right mb-3 mr-2 mt-4">
                                                            <Button className="btn btn-pink mr-1 ml-1" type="submit" disabled={!values.ClassList 
                                                            || isValidating || !dirty || !!(errors.ClassList && touched.ClassList) }
                                                            >Submit</Button>
                                                            <Link to={this.props.profileInfo && this.props.profileInfo.usertype && this.props.profileInfo.usertype === 2 
                                                                ? `/teacher/${this.props.getTeacherViewData.ldap_id}` : '/teachers_profile'}><Button className="btn btn-default mr-1 ml-1">Cancel</Button></Link>
                                                        </div>
                                                    </form>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTeacher}><SpinnerLoader/></div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        loading:state.teacher.loading,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
        errorMessage: state.teacher.errors,
        getClassList:state.diary.gradelist,
        getTeacherViewData:state.teacher.GetTeacherProfile,
        schoolIdDetails:state.profile.schoolId,
        mappingClassStatu:state.teacher.mappingClassIncharge,
        profileInfo:state.profile.profileData,
        mappingTeacherId:state.teacher.Add_Mapping_Teacher_id,
        getTeacherClassList:state.classes.OnGetData
    };
}

export default connect(mapStateToProps, {TeacherClassMappingPost, fetchGetAllClassList, onGetData, stopYearData})(AddClassInCharge)
