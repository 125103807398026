import React from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb'
import { fetchSubjectsPost, fetchSubject, deletePost } from '../../store/subject/Actions'
import { addSubject } from '../../store/subject/Actions';
import { Link } from 'react-router-dom';
import { SubjectFieldsType, SubjectTypes, loadMoreType, ISearchBarState } from '../../store/subject/Types'
import { UserDetails } from '../../store/authentication/Types'
import history from '../../History';
import { UserRoles } from '../../services/Constants'
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import Switch from '@material-ui/core/Switch';
import { formValidationPatten } from '../../services/Constants';
import {CommonModel} from '../../components/CommonModel';
import { stopYearData } from '../../store/profile/Actions'

export interface PostsListProps {
  loading: boolean
  getYear?:any;
  subjectDetails: SubjectTypes;
  subjectDetailDelete: SubjectFieldsType;
  tokenValue: UserDetails;
  addSubjectDetails: any;
  deleteDetails: any;
  fetchSubjectsPost: (getData: loadMoreType) => any;
  fetchSubject: (id: number) => void;
  deletePost: (subjectDetailDelete: number) => any;
  addSubject: (subject: SubjectFieldsType) => void;
  stopYearData:()=>any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?:any;
  profileInfo:any;
}

class ViewSubjectDetails extends React.Component<PostsListProps, ISearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteSubjectId: [],
      query: '',
      subjectsData: [],
      subjectsTotal: 1,
      hasMore: true,
      prev: 0,
      next: 0,
      acsOrder: true,
      descOrder: false,
      page: 1,
      search: '',
      getResponseData: [],
      SortOrderData: '',
      OrderNameData: '',
      postData:{},
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order',
      academicYear:null
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.UpdateYearDate();
    this.checkUserType();
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
      this.UpdateYearDate(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
    UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.setState({academicYear:getYear})
        this.getOnSubjectData(getYear)
    }else {
        this.setState({academicYear:this.props.getYear})
        this.getOnSubjectData(this.props.getYear)
    }
    }
  public getOnSubjectData(getYear?:any) {
    const postValue = {
      page_no: 1,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData,
      academic_year:getYear
    }
    this.props.fetchSubjectsPost(postValue).then((res:any)=>{
      window.scrollTo(0, 0);
      this.setState({hasMore:true, page:1})
    });
    
  }

  checkStatusProcess() {
    const selectDataList = this.props.deleteDetails;
    if (selectDataList === true) {
      this.UpdateYearDate();
    }
  }
  public fetchMoreData = () => {
    const {academicYear} = this.state
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: this.state.page + 1,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year:academicYear
        }
        this.props.fetchSubjectsPost(postValue);
        this.setState({
          page: this.state.page + 1
        })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  public handleSubjectDelete = () => {
    this.props.deletePost(this.state.deleteSubjectId).then((res:any)=>{
      this.checkStatusProcess()
    });
    this.setState({ showDelete: false, page: 1, hasMore: true });
  }

  public hideSubjectDelete = () => {
    this.setState({ showDelete: false })
  }
  public showSubjectDelete = (getData: any) => {
    let postValue:any
    let titleClassMessage: any;
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if(getData && titleClassMessage){
      postValue = {
        title:'Please Confirm',
        action_status:titleClassMessage,
        action_name:'Subject'
      }
      this.setState({ showDelete: true, deleteSubjectId: getData, postData:postValue });
    }
  }

  // This is the functio use to filter grit data
  public showFilterData = (getdata: any) => {
    const {academicYear} = this.state
    if (getdata.sort_by === 'desc') {
      this.setState({ acsOrder: false, sortOrder: getdata.sort_by, orderName: getdata.order_by, search: '' })
      const postValue = {
        page_no: 1,
        search: this.state.search,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        academic_year:academicYear
      }
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'asc',
        sort_class_name:'fa fa-sort-amount-desc',
        sort_title_name:'Descending order'
      })
      this.props.fetchSubjectsPost(postValue);
    } else {
      this.setState({ acsOrder: true, sortOrder: getdata.sort_by, orderName: getdata.order_by, search: '' })
      const postValue = {
        page_no: 1,
        search: this.state.search,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        academic_year:academicYear
      }
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'desc',
        sort_class_name:'fa fa-sort-amount-asc',
        sort_title_name:'Ascending order'
      })
      this.props.fetchSubjectsPost(postValue);
    }
  }
  // This is the function used to check user type
  checkUserType() {
    const {profileInfo} = this.props;
    let getSubjectToken:any;
    if(profileInfo.usertype){
      
      getSubjectToken = String(profileInfo.usertype);
    if (getSubjectToken === UserRoles.acadamicAdmin) {
      this.setState({ acadamicAdmin: true })
    } else if (getSubjectToken === UserRoles.schoolAdmin) {
      this.setState({ schoolAdmin: true })
    } else if (getSubjectToken === UserRoles.teacher) {
      this.setState({ teacher: true })
    } else if (getSubjectToken === UserRoles.parent) {
      this.setState({ parent: true })
    }
  }
  }

  private renderSubjectManageView(records: any) {
    const { loading } = this.props;
    const { sort_by, sort_class_name, sort_title_name, acadamicAdmin } = this.state
    const loadingTextCSS = { display: loading ? "block" : "none" };
    return (
      <div>
        <InfiniteScroll
          dataLength={records.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={<h4 style={loadingTextCSS}>Loading...</h4>}
        >
          <table className="table table-striped custom-table table-hover">
            <thead>
              <tr>
                <th>
                    <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'name' })}
                      title={sort_title_name} className="headerBold">Subject Name&nbsp;
                      <i className={sort_class_name} aria-hidden="true"></i></button> 
                </th>
                <th>
                  <button
                    onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'short_name' })}
                    title={sort_title_name} className="headerBold">
                    Short Name&nbsp; <i className={sort_class_name} aria-hidden="true"></i></button>  
                </th>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'category' })}
                    title={sort_title_name} className="headerBold">
                    Category&nbsp; <i className={sort_class_name} aria-hidden="true"></i></button>  </th>
                    {acadamicAdmin?
                    <>
                    <th><button className="headerBold">School Name </button>  </th>
                    <th className="UserTable">Actions</th>
                    </>
                    :null}
                
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ?
                records.map((data: SubjectFieldsType) => (
                  <tr>
                    <td >{data['name'] || '-'}</td>
                    <td >{data['short_name'] || '-'}</td>
                    <td >{data['category'] || '-'}</td>
                    {acadamicAdmin?
                      <>
                       <td className="w-50">
                    {data.school_list.length > 0 && data.school_list !== null?
                    data.school_list.map((items:any)=>(
                     <button className="btn btn-xs btn-circle btn-pink m-1">{items['school_name'] || '-'}</button>
                    ))
                    :null}
                    </td>
                    <td>                     
                      {data.is_active ?
                        <Link to={`/edit_subject/${data.id}`}>
                          <button className="btn btn-primary btn-xs"
                            title="Edit Subject"><i className="fa fa-pencil" aria-hidden="true" ></i></button></Link> :
                        <button className="btn btn-disable btn-xs"
                          title="Edit Subject"><i className="fa fa-pencil" aria-hidden="true" ></i></button>
                          }
                      
                      {data.is_active ?
                        <Switch
                        checked={data.is_active}
                        onClick={() => this.showSubjectDelete({ id: String(data['id']), isActive: false })}
                        name="checkedUser"
                        inputProps={{ 'aria-label': 'success checkbox' }}
                        title="Deactivate Subject"
                      /> :
                      <Switch
                      checked={data.is_active}
                      onClick={() => this.showSubjectDelete({ id: String(data['id']), isActive: true })}
                      name="checkedUser"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      title="Activate Subject"
                    />}
                    </td>
                      </>
                      :null
                    }
                   
                  </tr>
                ))
                : <CommonLoader />}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    )
  }

  handleInputSubjectChange = (e: any) => {
    const { value } = e.target;
    const {academicYear} = this.state
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
      if (getSearchValue) {
        const postSearchValue = {
          page_no: 1,
          search: getSearchValue,
          academic_year:academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchSubjectsPost(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue
        })
      } else {
        const postSearchValue = {
          page_no: 1,
          search: '',
          academic_year:academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchSubjectsPost(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: ''
        })
      }
    }

  }
  refreshSubjectTable = () =>{
    const {academicYear} = this.state
    this.getOnSubjectData(academicYear);
  }
  render() {
    const { loading } = this.props;
    const { showDelete, postData } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const getSubjectToken = localStorage.getItem('token');
    const subjectTotal = this.props.total;
    if (!getSubjectToken) {
      history.push("/");
    }
    return (
      <div>
            <CommonModel
       onState={showDelete}
       currentState={postData}
       onConfirm={this.handleSubjectDelete}
       onCancel={this.hideSubjectDelete}
       />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb titleName={['Subject']} homeName={['Home']} url={['dashboard']} mainPageTitle={['Subject']} />
              <div className="row">
                <div className="col-md-12 mb-3">
              <div className="mr-4">
                      <h4 className="pull-right">Total: {subjectTotal}</h4>
                    </div>
                    </div>
                    </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-topline-red">
                    <div className="card-head">
                      <header>
                        {/* <Link to="/add_subject" style={{ display: acadamicAdmin ? "block" : "none" }}>
                          <button className="btn btn-pink">Map Subject</button>
                        </Link> */}
                      </header>
                      <div className="tools">
                      <div className="d-flex">
										<div>
										        <input
                              placeholder="Search"
                              name="search"
                              className="form-control"
                              onChange={this.handleInputSubjectChange}                           
                            />
										</div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshSubjectTable}></span>
											<span className="t-collapse btn-color fa fa-chevron-down pointer" ></span>
										</div>
									</div>
                  
                      </div>
                    </div>
                    <div className="card-body no-padding height-9">
                      <div className="row">

                        <div className="table-responsive">
                          {this.renderSubjectManageView(this.props.records)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
		stopYear:state.profile.yearStatusFails,
    subjectDetails: state.subjects.items,
    loading: state.subjects.loading,
    total: state.subjects.total,
    per_page: state.subjects.per_page,
    records: state.subjects.records,
    page: state.subjects.page,
    totalPage: state.subjects.totalPage,
    deleteDetails: state.subjects.isAuthenticated,
    profileInfo:state.profile.profileData,
  };
};
export default connect(
  mapStateToProps, { fetchSubjectsPost, fetchSubject, deletePost, addSubject, stopYearData }
)(ViewSubjectDetails);