import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchDashboard } from '../../store/dashboard/Actions';
import { stopYearData, stopSubComponentYear } from '../../store/profile/Actions'

export interface PostsAcademicProps {
	getChangeYearData?:any;
	stopYearData:()=>any;
	fetchDashboard: (postValue: any) => void;
	stopSubComponentYear:(postValue:any) => any;
	getCountList: any;
	stopYearDate?:any;
	dashboardCountLoader?:boolean
}
export class AcademicAdminCount extends Component<PostsAcademicProps> {
	public state = {
        getYear:null
    }
	componentDidMount(): void {
	this.getCurrectYear()
	}
	componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
			const postValue:any = {
				status:true
			}
			this.props.stopSubComponentYear(postValue)
		}
	}
	componentDidUpdate() {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.setState({getYear:getYear})
			this.getAcademicAdminCount(getYear)
		}else {
			this.setState({getYear:this.props.getChangeYearData})
			this.getAcademicAdminCount(this.props.getChangeYearData)
		}
	}
	getAcademicAdminCount(getYear?:any){
		window.scrollTo(0, 0);
		if(getYear){
			const postValue = {
				academic_year: getYear
			}
			this.props.fetchDashboard(postValue);
		}
	}
    render() {
		const {dashboardCountLoader} = this.props;
		const loaderDashboardCount = { display: dashboardCountLoader ? "block" : "block", borderRadius:"25px"  };
		const { getCountList } = this.props;
		if(getCountList){
			getCountList.forEach((items:any)=> {
				if(items.title === 'Schools'){
				const schoolName:any = getCountList.find((item:any)=> item.title === 'Schools')
					if(schoolName.title === 'Schools'){
						items['bg_class'] = "bg-blue";
						items['icon_class'] = "fa fa-graduation-cap";
					}
				}else if(items.title === 'Teachers'){
					const getTeachers:any = getCountList.find((item:any)=> item.title === 'Teachers')
					if(getTeachers){
						items['bg_class'] = "bg-orange";
						items['icon_class'] = "fa fa-users";					}
					
				}else if(items.title === 'Students') {
					const getStudents:any = getCountList.find((item:any)=> item.title === 'Students')
					if(getStudents){
						items['bg_class'] = "bg-success";
						items['icon_class'] = "fa fa-graduation-cap";
					}					
				}else if(items.title === 'Quizzes') {
					const getStudents:any = getCountList.find((item:any)=> item.title === 'Quizzes')
					if(getStudents){
						items['bg_class'] = "bg-purple";
						items['icon_class'] = "fa fa-file-text";
					}					
				}	
				
			})
		}
        return (
            <div>
                <div className="state-overview">
					
				<div className="row">
					{getCountList && getCountList.length !== 0?
						getCountList.map((items:any)=>(
							<div className="col-xl-3 col-md-6 col-12">
						<div className={`info-box p-0 pl-3 ${items.bg_class}`}>
							<span className="info-box-icon push-bottom">
							<i className={items.icon_class} aria-hidden="true"></i>
							</span>
							<div className="info-box-content">
								<span className="info-box-text">{items.title}</span>
								<span className="info-box-number">{items.count}</span>
								<div className="progress">
									<div className="progress-bar width-60"></div>
								</div>
								<span className="progress-description">
									Total {items.title}
								</span>
							</div>
							{/* info-box-content */}
						</div>
						{/* info-box */}
					</div>
					)) :
							<div className="col-12 mb-3" >
								<div className={`info-box`} style={{ marginBottom: 0 }}>
									<div className="overlay" style={loaderDashboardCount}><div className='custom-spinner-individual'></div></div>
								</div>
							</div>
					}
					
				</div>
				
			</div>
			{/* end widget */}
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
	return {
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
		getCountList:state.dashboard.DashboardCount,
		dashboardCountLoader:state.dashboard.dashboardCountLoader
	}
}


export default connect(mapStateToProps, {fetchDashboard, stopYearData, stopSubComponentYear})(AcademicAdminCount)
