
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Doughnut } from 'react-chartjs-2';
import { getFeedBackReport } from '../store/profile/Actions';
import history from '../History';
import {optionDoughnut} from '../components/chartoption/ChartCommonFun';

interface PropsTypes {
    getFeedBackReport: (postValue: any) => any;
    getData?:any,
    onInfo?:any
}

export class CommonSubjectList extends Component<PropsTypes> {
        // This is a function used to post topic details
        getTopicDetails(getValue?: any, getAllValue?: any) {
            const studentDetails: any = this.props.onInfo;
            history.push({
                pathname: `/topics/${getValue}`
            });
            let postData: any;
            if(studentDetails.class_id){
                postData = {
                    feedback: getValue,
                    subject_id: getAllValue.subject_id,
                    school_id: studentDetails.school_id,
                    pageName: 'View Student',
                    card_id: studentDetails.card_id,
                    class_id: studentDetails.class_id
                }
            }else {
                postData = {
                    feedback:getValue,
                    subject_id:getAllValue.subject_id,
                    pageName:'Teacher',
                    teacher_id:studentDetails.teacher_id,
                    school_id:studentDetails.school_id
                }
            }
            if(postData){
                this.props.getFeedBackReport(postData)
            }
        }
    render() {
        return (
            <div>
                {this.props.getData ?
                            this.props.getData.map((items: any) => (
                                <div className="card card-topline-red">
                                <div className="card-head">
                                    <header>
                                        <h4>{items.subject_name}</h4></header>
                                    <div className="tools">
                                    </div>
                                </div>
                                <div className="card-body no-padding height-9 subjectboxbg">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="row mb-3">
                                                <Doughnut
                                                    data={{
                                                        labels: ['Correct', 'Incorrect', 'Not Attempted'],
                                                        datasets: [{
                                                            data: items.count ? items.count : items.percentage,
                                                            backgroundColor: items.color_code
                                                        }]
                                                    }}
                                                    options={optionDoughnut}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <div className="subjectbox mb-2">
                                                <h5 className="subjectboxtitle p-2">Correct Answer</h5>
                                                <h5 className="p-2">{items.correct_answer}</h5>
                                            </div>
                                            <div className="subjectbox mb-2">
                                                <h5 className="subjectboxtitleNot p-2">Not Attempted</h5>
                                                <h5 className="p-2">{items.not_attempted}</h5>
                                            </div>
                                            <div className="subjectbox mb-2">
                                                <h5 className="subjectboxtitleInccort p-2">Incorrect Answer</h5>
                                                <h5 className="p-2">{items.wrong_answer}</h5>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center ml-3 mr-3">
                                        <div> Good Topics</div>
                                            <hr className="m-0 mt-2" />
                                            {items.good_topics ?
                                                <>
                                                    {items.good_topics.map((goodTopics: any) => (
                                                        <button className="btn
                                            btn-danger btn-xs mt-3 ml-2"  onClick={(e: any) => this.getTopicDetails(goodTopics, items)}>{goodTopics}</button>
                                                    ))}
                                                </>
                                                : null}
                                        </div>
                                        <div className="col-md-3 text-center ml-3 mr-3">
                                        <div>Weak Topics</div>
                                            <hr className="m-0 mt-2" />
                                            {items.weak_topics ?
                                                <>
                                                    {
                                                        items.weak_topics.map((weakTopics: any) => (
                                                            <button className="btn
                                            btn-danger 
                                            btn-xs mt-3 mr-2" onClick={(e: any) => this.getTopicDetails(weakTopics, items)}>{weakTopics}</button>
                                                        ))
                                                    }
                                                </>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))
                            : null}
            </div>
        )
    }
}
export const option = {
    legend: {
        display: false
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem: any, data: any) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                var total = meta.total;
                var currentValue = dataset.data[tooltipItem.index];
                var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                return `${currentValue} ${ percentage} %)'`;
            },
            title: function (tooltipItem: any, data: any) {
                return data.labels[tooltipItem[0].index];
            }
        }
    }
}

const mapStateToProps = (state:any) => ({
    
})

export default connect(mapStateToProps, {getFeedBackReport})(CommonSubjectList)
