import { Reducer } from 'redux';
import { UserActionTypes, UserState } from './Type'
export const initialState: UserState = {
    items: [],
    loading: false,
    isAuthenticated: null,
    category: [],
    page: 1,
    per_page: 10,
    records: [],
    total: 0,
    errorMessage: [],
    downloadUserGuideFiles: null,
    institutionDetails: null
}

const reducer: Reducer<UserState> = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.FETCH_USER:
        case UserActionTypes.ADD_USER:
        case UserActionTypes.DELETE_USER:
        case UserActionTypes.EDIT_USER:
        case UserActionTypes.FETCH_USER_ID:
        case UserActionTypes.POST_UPDATE_USER_DATA:
            return {
                ...state,
                loading: true,
                errorMessage: []
            };
        case UserActionTypes.FETCH_USER_CATEGORY:
            return {
                ...state,
                loading: true,
                errorMessage: [],
                category: []
            };
        case UserActionTypes.FETCH_USER_FAIL:
        case UserActionTypes.FETCH_USER_FAIL_ID:
        case UserActionTypes.EDIT_USER_FAIL:
        case UserActionTypes.DELETE_USER_FAIL:
        case UserActionTypes.ADD_USER_FAIL:
        case UserActionTypes.ADD_USER_SUCCESS:
        case UserActionTypes.EDIT_USER_SUCCESS:
        case UserActionTypes.FETCH_USER_FAIL_CATEGORY:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errorMessage: action.payload.data
            };

        case UserActionTypes.POST_UPDATE_USER_DATA_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                // errorMessage: action.payload.data
            };

        case UserActionTypes.FETCH_USER_SUCCESS_ID:
        case UserActionTypes.POST_UPDATE_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
                isAuthenticated: false
            };
        case UserActionTypes.FETCH_USER_SUCCESS:
            const { payload, records, per_page, page, total } = action;
            if (page === 1) {
                state.records = []
            }
            return {
                ...state,
                loading: false,
                items: payload,
                page: page,
                per_page: per_page,
                records: [...state.records, ...records],
                total: total,
                totalPage: Math.ceil(total / per_page),
                isAuthenticated: false,
            }
        case UserActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                items: action.payload,
                loading: false,
                isAuthenticated: true
            };
        case UserActionTypes.FETCH_USER_SUCCESS_CATEGORY:
            return {
                ...state,
                category: action.payload,
                loading: false
            }
        case UserActionTypes.DOWNLOAD_USER_GUIDE:
            return {
                ...state,
                loading: true,
                downloadUserGuideFiles: null
            };
        case UserActionTypes.DOWNLOAD_USER_GUIDE_SUCCESS:
            const { downloadUserGuideFiles } = action
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                downloadUserGuideFiles: downloadUserGuideFiles
            };
        case UserActionTypes.DOWNLOAD_USER_GUIDE_FAIL:
            const { errors } = action;
            return {
                ...state,
                errors: errors,
                loading: false,
                isAuthenticated: true,
                total: 0
            };
        case UserActionTypes.Reset_UserManage_Details:
            return initialState;
        case UserActionTypes.POST_INSTITUTION_DETAILS:

            
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
            };
        case UserActionTypes.POST_INSTITUTION_SUCCESS:
            const { institutionDetailss } = action.payload
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                // institutionDetails: action.payload
            };
        case UserActionTypes.POST_INSTITUTION_FAILURE:

            // const { institutionDetailss } = action.payload
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                institutionDetails: institutionDetailss
            };
        default:
            return state;
    }
}

export { reducer as userReducer }